import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Editor} from "@tinymce/tinymce-react";
import cn from "classnames";
import dayjs from "dayjs";
import GoogleMapReact from "google-map-react";
import i18n from "i18next";
import _ from "lodash";
import React, {useCallback, useEffect, useRef, useState} from "react";
import DatePicker from "react-datepicker";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {NotificationManager} from "react-notifications";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {getAllParkingZoneAdmins} from "../../actions/admin";
import {getAllClients} from "../../actions/client";
import {getAllParkingZoneManagers, getManagersByAdminId,} from "../../actions/manager";
import {getAllOwners, getAllOwnersForPZAdmin} from "../../actions/owners";
import {
  clearPZForEdit,
  editParkingZone,
  geocodeAddressFetchFirstResult,
  geocodeAddressSetMarkers
} from "../../actions/parkingZone";
import {getAllViewers, getViewersByAdminId} from "../../actions/viewer";
import AddressesComponent, {formatAddress} from "../../common/address/AddressesComponent";
import XIcon from "../../common/assets/x-icon.svg";
import Button from "../../common/button/button";
import InputController from "../../common/input/inputController";
import TimePickerInputWithLabel from "../../common/input/timePickerInputWithLabel";
import LabelWithHR from "../../common/labels/labelWithHR";
import {ADDRESS_GEOCODING_DELAY, defaultMarker, manualParkingZoneMarkerEnabled, ROLES} from "../../consts";
import ModalLoadingIndicator from "../../reusable/modalLoadingIndicator";
import {newModalStyle} from "../../reusable/styles";
import {formatCurrencyCode} from "../../utils/formatter";
import {mapUser} from "../../utils/mapUser";
import Style from "./editParkingZoneModal.module.css";
import Marker, {colorMapOptions, dimmedMapOptions, moveMapCenterByCountry} from "./marker";

const EditParkingZoneModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, watch, register, control, setValue } = useForm();
  const auth = useAuth();
  const loggedUser = mapUser(auth);

  const { admins } = useSelector((state) => state.admin);
  const { managers } = useSelector((state) => state.manager);
  const { viewers } = useSelector((state) => state.viewer);
  const { clients } = useSelector((state) => state.client);
  const beppolicemans = useSelector((state) => state.owners.owners);
  const { parkingZoneForUpdate } = useSelector((state) => state.parkingZone);
  const { isLoading } = useSelector((state) => state.loader);

  const editorRef = useRef(null);

  const [modifyParkingZone, setModifyParkingZone] = useState({
    parkingZoneName: parkingZoneForUpdate?.parkingZoneName,
    payee: parkingZoneForUpdate?.payee,
    bic: parkingZoneForUpdate?.bic,
    iban: parkingZoneForUpdate?.iban,
    financialInstitution: parkingZoneForUpdate?.financialInstitution,
    address: parkingZoneForUpdate?.address,
    keycloakAdminId: parkingZoneForUpdate?.keycloakAdminId,
    notedBy: parkingZoneForUpdate?.notedBy,
    injuredPerson: parkingZoneForUpdate?.injuredPerson,
    representedBy: parkingZoneForUpdate?.representedBy,
    additionalInfo: parkingZoneForUpdate?.additionalInfo,
    staticPinCode: parkingZoneForUpdate?.staticPinCode,
    relationToProperty: parkingZoneForUpdate?.relationToProperty,
    viacarIntegrationImmediately:
      parkingZoneForUpdate?.viacarIntegrationImmediately,
    parkingPayZone: parkingZoneForUpdate?.parkingPayZone,
    markers: parkingZoneForUpdate?.markers?.map((marker) => {
      return { center: { lat: marker.latitude, lng: marker.longitude } };
    }),
    creditFormula: parkingZoneForUpdate?.creditFormula || "FORMULA_1",
    invoicingFormula: parkingZoneForUpdate?.invoicingFormula || "FIXED",
    customerShare: parkingZoneForUpdate?.customerShare,
    commissionFormula: parkingZoneForUpdate?.commissionFormula || "FIXED",
    commissionShare: parkingZoneForUpdate?.commissionShare,
    customer: parkingZoneForUpdate?.customer,
    videoAnalyzed: parkingZoneForUpdate?.videoAnalyzed,
    clientId: parkingZoneForUpdate?.client?.id,
    useClientDocs: parkingZoneForUpdate?.useClientDocs
  });

  const [parkingZoneText, setParkingZoneText] = useState("");

  const [powerOfAttorneyName, setPowerOfAttorneyName] = useState("");
  const [landRegisterName, setLandRegisterName] = useState("");
  const [parkingPhotoName, setParkingPhotoName] = useState("");
  const [parkingZoneNumber, setParkingZoneNumber] = useState("");
  const [documentation, setDocumentation] = useState(null);
  const [documentationName, setDocumentationName] = useState("");
  const [powerOfAttorney, setPowerOfAttorney] = useState(null);
  const [landRegister, setLandRegister] = useState(null);
  const [parkingPhoto, setParkingPhoto] = useState(null);
  const [dateOfCourtDecision, setDateOfCourtDecision] = useState(null);
  const [contractDate, setContractDate] = useState(null);
  const [salesArea, setSalesArea] = useState("");
  const [pzComment, setPzComment] = useState("");
  const [automaticSignedOut, setAutomaticSignedOut] = useState("");
  const [publicationInCantonalNewspaper, setPublicationInCantonalNewspaper] =
    useState(null);

  const [managersIds, setManagersIds] = useState([]);
  const [viewersIds, setViewersIds] = useState([]);
  const [managersComboBoxNames, setManagersComboBoxNames] = useState([]);
  const [viewersComboBoxNames, setViewersComboBoxNames] = useState([]);
  const [beppolicemansIds, setBeppolicemansIds] = useState([]);
  const [deletedBeppolicemansIds, setDeletedBeppolicemansIds] = useState([]);
  const [beppolicemansComboBoxNames, setBeppolicemansComboBoxNames] = useState(
    []
  );
  const [counter, setCounter] = useState(0);
  const [viewerCounter, setViewerCounter] = useState(0);


  const isLoggedUserSysadmin = loggedUser?.roles?.includes(ROLES.SYSADMIN);
  const [useClientDocs, setUseClientDocs] = useState(!!parkingZoneForUpdate?.useClientDocs);
  const [objectionEnabled, setObjectionEnabled] = useState(false);
  const [additionalInfoForTickets, setAdditionalInfoForTickets] = useState(false);
  const [parkingPermitEnabled, setParkingPermitEnabled] = useState(!!parkingZoneForUpdate?.parkingPermitDurationTime);
  const [useMarkerForProximityCheck, setUseMarkerForProximityCheck] = useState(!!parkingZoneForUpdate?.useMarkerForProximityCheck);
  

  const [map, setMap] = useState();
  const [geocoder, setGeocoder] = useState();

  const [initialRecenterAlreadyDone, setInitialRecenterAlreadyDone] = useState(false);

  const countryWatch = watch("country", parkingZoneForUpdate?.address?.countryCode);
  const numberOfParkingPlacesWatch = watch("numberOfParkingPlaces", parkingZoneForUpdate?.numberOfParkingPlaces);
  const priceWatch = watch("price", parkingZoneForUpdate?.price);
  const paymentPeriodWatch = watch("paymentPeriod", parkingZoneForUpdate?.paymentPeriod);
  const reminderFeeWatch = watch("reminderFee", parkingZoneForUpdate?.reminderFee);
  const additionalCostWatch = watch("additionalCost", parkingZoneForUpdate?.additionalCost);
  const reminderPaymentPeriodWatch = watch("reminderPaymentPeriod", parkingZoneForUpdate?.reminderPaymentPeriod);

  const calculateDurationTime = (minutes) => {
    if (!minutes) return "00:00";
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };

  const setClientId = (id) => {
      setModifyParkingZone({
        ...modifyParkingZone,
        clientId: id,
        customer: id === null ? "" : modifyParkingZone.customer,
      });
    };

  const [parkingPermitTime, setParkingPermitTime] = useState(
    parkingZoneForUpdate?.parkingPermitDurationTime
      ? dayjs(
          `2022-04-17T${calculateDurationTime(
            parkingZoneForUpdate?.parkingPermitDurationTime
          )}`
        )
      : null
  );
  const [freezeTime, setFreezeTime] = useState(
    parkingZoneForUpdate?.freezeTime
      ? dayjs(
          `2022-04-17T${calculateDurationTime(
            parkingZoneForUpdate?.freezeTime
          )}`
        )
      : null
  );

  useEffect(() => {
    if (isLoggedUserSysadmin) {
      dispatch(getAllParkingZoneAdmins());
      dispatch(getAllParkingZoneManagers());
      dispatch(getAllViewers());
      dispatch(getAllOwners());
      dispatch(getAllClients());
    } else {
      dispatch(getManagersByAdminId());
      dispatch(getAllOwnersForPZAdmin());
      dispatch(getViewersByAdminId());
    }
    return () => {
      dispatch(clearPZForEdit());
    };
  }, [dispatch, isLoggedUserSysadmin]);

  useEffect(() => {
    if (
      parkingPermitEnabled === true &&
      parkingZoneForUpdate?.parkingPermitDurationTime
    ) {
      setParkingPermitTime(
        dayjs(
          `2022-04-17T${calculateDurationTime(
            parkingZoneForUpdate?.parkingPermitDurationTime
          )}`
        )
      );
      setFreezeTime(
        dayjs(
          `2022-04-17T${calculateDurationTime(
            parkingZoneForUpdate?.freezeTime
          )}`
        )
      );
    } else if (parkingPermitEnabled === true) {
      setParkingPermitTime(dayjs("2022-04-17T01:00"));
      setFreezeTime(dayjs("2022-04-17T00:15"));
    } else {
      setParkingPermitTime(null);
      setFreezeTime(null);
    }
  }, [parkingPermitEnabled]);

  useEffect(() => {
    setAutomaticSignedOut(
      new Date(
        new Date().setHours(parkingZoneForUpdate?.automaticSignedOut, "0")
      )
    );
    setObjectionEnabled(parkingZoneForUpdate?.objectionEnabled);
    setAdditionalInfoForTickets(parkingZoneForUpdate?.additionalInfoForTickets);
    setParkingZoneNumber(parkingZoneForUpdate.parkingZoneNumber);
    setParkingZoneText(parkingZoneForUpdate?.parkingZoneText);
    setSalesArea(parkingZoneForUpdate?.salesArea);
    setPzComment(parkingZoneForUpdate?.pzComment);
    setContractDate(
      parkingZoneForUpdate?.contractDate
        ? new Date(parkingZoneForUpdate?.contractDate)
        : null
    );
    setDateOfCourtDecision(
      parkingZoneForUpdate?.dateOfCourtDecision
        ? new Date(parkingZoneForUpdate?.dateOfCourtDecision)
        : null
    );
    setPublicationInCantonalNewspaper(
      parkingZoneForUpdate?.publicationInCantonalNewspaper
        ? new Date(parkingZoneForUpdate?.publicationInCantonalNewspaper)
        : null
    );
    if (!parkingZoneForUpdate?.managersIds) {
      setManagersIds([]);
      setManagersComboBoxNames([]);
      setCounter(0);
    } else {
      setManagersIds(parkingZoneForUpdate?.managersIds);
      const array = parkingZoneForUpdate?.managersIds.map(
        (m, index) => index + 1
      );
      setManagersComboBoxNames(array);
      setCounter(parkingZoneForUpdate?.managersIds.length);
    }

    if (!parkingZoneForUpdate?.viewersIds) {
      setViewersIds([]);
      setViewersComboBoxNames([]);
      setViewerCounter(0);
    } else {
      setViewersIds(parkingZoneForUpdate?.viewersIds);
      const array = parkingZoneForUpdate?.viewersIds.map(
          (m, index) => index + 1
      );
      setViewersComboBoxNames(array);
      setViewerCounter(parkingZoneForUpdate?.viewersIds.length);
    }

    if (!parkingZoneForUpdate?.beppolicemansIds) {
      setBeppolicemansIds([]);
      setBeppolicemansComboBoxNames([]);
    } else {
      setBeppolicemansIds(parkingZoneForUpdate?.beppolicemansIds);
      const array = parkingZoneForUpdate?.beppolicemansIds.map(
        (b, index) => index + 1
      );
      setBeppolicemansComboBoxNames(array);
    }

    setPowerOfAttorneyName(
      parkingZoneForUpdate.powerOfAttorneyName
        ? parkingZoneForUpdate.powerOfAttorneyName
        : ""
    );

    setLandRegisterName(
      parkingZoneForUpdate.landRegisterName
        ? parkingZoneForUpdate.landRegisterName
        : ""
    );
    setDocumentationName(
      parkingZoneForUpdate.documentationName
        ? parkingZoneForUpdate.documentationName
        : ""
    );
    setParkingPhotoName(
      parkingZoneForUpdate.parkingPhotoName
        ? parkingZoneForUpdate.parkingPhotoName
        : ""
    );
  }, [parkingZoneForUpdate]);

  useEffect(() => moveMapCenterByCountry(modifyParkingZone?.markers, countryWatch, map), [countryWatch]);

  useEffect(() => {
    if (modifyParkingZone?.videoAnalyzed) {
      // clear useMarkerForProximityCheck if this is video analyzed PZ
      setUseMarkerForProximityCheck(false)
    } else if (!modifyParkingZone?.markers?.length) {
      // clear useMarkerForProximityCheck if there are no markers
      setUseMarkerForProximityCheck(false)
    }
  }, [modifyParkingZone?.markers, modifyParkingZone?.videoAnalyzed]);

  const documentUpload = (e, files, setFiles, filesNames, setFilesNames) => {
    if (e.target.files.length !== 0) {
      let fileList = files === null ? [] : files;
      for (let i = 0; i < e.target.files.length; i++) {
        fileList.push(e.target.files[i]);
      }
      setFiles(fileList);

      let names = filesNames;
      for (let i = 0; i < e.target.files.length; i++) {
        if (names === "" && i === 0) {
          names = names.concat(e.target.files[i].name);
        } else {
          names = names.concat("|", e.target.files[i].name);
        }
      }
      setFilesNames(names);
    }
  };

  const removeDocument = (
    files,
    setFiles,
    filesNames,
    setFilesNames,
    elementForRemoveName
  ) => {
    files !== null &&
      setFiles([...files?.filter((f) => f.name !== elementForRemoveName)]);
    const namesArray = filesNames
      ?.split("|")
      .filter((fn) => fn !== elementForRemoveName);
    setFilesNames(namesArray?.join("|"));
  };

  const showDocumentsNames = (files, setFiles, filesNames, setFilesNames) => {
    return filesNames?.split("|")?.map((n, index) => (
      <div key={index} className={Style.documentRow}>
        <div className={Style.uploadedFileName}>{n}</div>
        <div className={Style.documentLine} />
        <div className={Style.removeDocument}>
          <img
            alt=""
            src={XIcon}
            height="25px"
            width="25px"
            onClick={() => {
              removeDocument(files, setFiles, filesNames, setFilesNames, n);
            }}
          />
        </div>
      </div>
    ));
  };

  const setParkingZoneAdmin = (id) => {
    setModifyParkingZone({
      ...modifyParkingZone,
      keycloakAdminId: id,
    });
    if (managersIds?.includes(id)) {
      setManagersIds([...managersIds?.filter((m) => m !== id)]);
    }
  };

  const renderEditManagers = (managersIds) => {
    const getListOfManagers = (managers, ordinalNumber) => {
      if (ordinalNumber === 1) return managers;
      return managers.filter(
        (m) => !managersIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
      <div className={Style.paddingTop}>
        <div className={Style.section__title}>{"PZ Manager"}</div>
        {managersIds.map((manager, index) => (
          <div className={Style.managerListContainer} key={index}>
            <div className={Style.selectManager}>
              <Controller
                name={`parkingZoneManager_${managersComboBoxNames[index]}`}
                control={control}
                render={() => (
                    <Autocomplete
                        className={Style.textStyleSelectEditModal}
                        id={`parkingZoneManager_${managersComboBoxNames[index]}`}
                        onChange={(e,value)  => {
                          if(value) {
                            managersIds[index] = value.id;
                            setManagersIds([...managersIds]);
                          }
                        }}
                        disableClearable
                        value = {managers.find(x=>x.id===manager) || null}
                        renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                        options={getListOfManagers(managers, index + 1).filter((m) => m.id !== modifyParkingZone?.keycloakAdminId)}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                    />
                )}
              />

            </div>
            <div className={Style.removeManager}>
              <img
                alt=""
                src={XIcon}
                height="25px"
                width="25px"
                onClick={(e) => {
                  setManagersIds([
                    ...managersIds.filter((m, i) => i !== index),
                  ]);
                  managersComboBoxNames.splice(index, 1);
                  setManagersComboBoxNames([...managersComboBoxNames]);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderEditViewers = (viewerIds) => {
    const getListOfViewers = (viewers, ordinalNumber) => {
      if (ordinalNumber === 1) return viewers;
      return viewers.filter(
          (m) => !viewerIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
        <div className={Style.paddingTop}>
          <div className={Style.section__title}>{t("Viewer.Viewers")}</div>
          {viewerIds.map((viewer, index) => (
              <div className={Style.managerListContainer} key={index}>
                <div className={Style.selectManager}>
                  <Controller
                      name={`parkingZoneViewer_${viewersComboBoxNames[index]}`}
                      control={control}
                      render={() => (
                          <Autocomplete
                              className={Style.textStyleSelectEditModal}
                              id={`parkingZoneViewer_${viewersComboBoxNames[index]}`}
                              onChange={(e,value)  => {
                                if(value) {
                                  viewerIds[index] = value.id;
                                  setViewersIds([...viewerIds]);
                                }
                              }}
                              disableClearable
                              value = {viewers.find(x=>x.id===viewer) || null}
                              renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                              options={getListOfViewers(viewers, index + 1).filter((m) => m.id !== modifyParkingZone?.keycloakAdminId)}
                              getOptionSelected={(option, value) => option.id === value.id}
                              getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                          />
                      )}
                  />

                </div>
                <div className={Style.removeManager}>
                  <img
                      alt=""
                      src={XIcon}
                      height="25px"
                      width="25px"
                      onClick={(e) => {
                        setViewersIds([
                          ...viewerIds.filter((m, i) => i !== index),
                        ]);
                        viewersComboBoxNames.splice(index, 1);
                        setViewersComboBoxNames([...viewersComboBoxNames]);
                      }}
                  />
                </div>
              </div>
          ))}
        </div>
    );
  };

  const renderEditBeppolicemans = (beppolicemansIds) => {
    const getListOfBeppolicemans = (beppolicemans, ordinalNumber) => {
      if (ordinalNumber === 1) return beppolicemans;
      return beppolicemans.filter(
        (m) => !beppolicemansIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
      <div className={Style.paddingTop}>
        <div className={Style.section__title}>
          {t("EditParkingZoneModal.PZBeppoliceman")}
        </div>
        {beppolicemansIds.map((beppoliceman, index) => (
          <div className={Style.managerListContainer} key={index}>
            <div className={Style.selectManager}>
              <Controller
                name={`parkingZoneBeppoliceman_${beppolicemansComboBoxNames[index]}`}
                control={control}
                render={() => (
                  <select
                    type="text"
                    className={cn(
                      Style.textStyleSelectEditModal,
                      Style.noArrowSelect
                    )}
                    name={`parkingZoneBeppoliceman_${beppolicemansComboBoxNames[index]}`}
                    onChange={(e) => {
                      beppolicemansIds[index] = e.target.value;
                      setBeppolicemansIds([...beppolicemansIds]);
                    }}
                  >
                    {getListOfBeppolicemans(beppolicemans, index + 1).map(
                      (user, index) => {
                        return (
                          user.id === beppoliceman && (
                            <option
                              value={beppoliceman}
                              key={index + 1}
                              selected
                            >
                              {user.firstName} {user.lastName}
                            </option>
                          )
                        );
                      }
                    )}
                  </select>
                )}
              />
            </div>
            <div className={Style.removeManager}>
              <img
                alt="X"
                src={XIcon}
                height="25px"
                width="25px"
                onClick={(e) => {
                  deletedBeppolicemansIds.push(
                    beppolicemansIds.find((b, i) => i === index)
                  );
                  setDeletedBeppolicemansIds([...deletedBeppolicemansIds]);

                  setBeppolicemansIds([
                    ...beppolicemansIds.filter((m, i) => i !== index),
                  ]);
                  beppolicemansComboBoxNames.splice(index, 1);
                  setBeppolicemansComboBoxNames([
                    ...beppolicemansComboBoxNames,
                  ]);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const isSubmitDisabled =
    !modifyParkingZone?.address ||
    !numberOfParkingPlacesWatch ||
    !priceWatch ||
    !paymentPeriodWatch ||
    reminderFeeWatch === "" ||
    additionalCostWatch === "" ||
    !reminderPaymentPeriodWatch ||
    modifyParkingZone?.keycloakAdminId === "0" ||
    managersIds?.includes("0") ||
    managersIds?.includes(modifyParkingZone?.keycloakAdminId) ||
    viewersIds?.includes("0") ||
    viewersIds?.includes(modifyParkingZone?.keycloakAdminId) ||
    (parkingPermitEnabled &&
      parkingPermitTime?.$H * 60 + parkingPermitTime?.$m === 0) ||
    (parkingPermitEnabled && freezeTime?.$H * 60 + freezeTime?.$m === 0);

  const saveParkingZone = async (data) => {
    const formData = new FormData();

    for (let i = 0; i < powerOfAttorney?.length; i++) {
      formData.append("powerOfAttorney", powerOfAttorney[i]);
    }
    for (let i = 0; i < landRegister?.length; i++) {
      formData.append("landRegister", landRegister[i]);
    }
    for (let i = 0; i < parkingPhoto?.length; i++) {
      formData.append("parkingPhoto", parkingPhoto[i]);
    }
    for (let i = 0; i < documentation?.length; i++) {
      formData.append("documentation", documentation[i]);
    }
    formData.append("powerOfAttorneyName", powerOfAttorneyName);
    formData.append("landRegisterName", landRegisterName);
    formData.append("documentationName", documentationName);
    formData.append("parkingPhotoName", parkingPhotoName);

    const { markers, ...modifyParkingZoneWithoutMarkers } = modifyParkingZone;
    const {
      numberOfParkingPlaces,
      price,
      paymentPeriod,
      reminderFee,
      additionalCost,
      reminderPaymentPeriod
    } = data;

    const data2 = {
      parkingZoneText: editorRef.current.getContent(),
      numberOfParkingPlaces,
      price,
      paymentPeriod,
      reminderFee,
      additionalCost,
      reminderPaymentPeriod,
      ...modifyParkingZoneWithoutMarkers,
      markers: markers.map((marker) => {
        return { latitude: marker.center.lat, longitude: marker.center.lng };
      }),
      useMarkerForProximityCheck,
      parkingPermitDurationTime: parkingPermitEnabled
        ? parkingPermitTime?.$H * 60 + parkingPermitTime?.$m
        : null,
      freezeTime: parkingPermitEnabled
        ? freezeTime?.$H * 60 + freezeTime?.$m
        : null,
      managersIds,
      viewersIds,
      beppolicemansIds,
      objectionEnabled,
      additionalInfoForTickets,
      dateOfCourtDecision,
      publicationInCantonalNewspaper,
      salesArea,
      pzComment,
      contractDate,
      deletedBeppolicemansIds,
      automaticSignedOut: automaticSignedOut.getHours(),
      useClientDocs,
    };
    formData.append(
      "parkingZone",
      new Blob([JSON.stringify(data2)], {
        type: "application/json",
      })
    );

    await dispatch(
      editParkingZone(parkingZoneForUpdate.id, formData, props.onClose)
    );
  };

  function setMarkers(markers: any[]) {
    setModifyParkingZone({
      ...modifyParkingZone,
      markers: markers,
    });
  }

  const fixupMarkers = async () => {
    if (map && geocoder && modifyParkingZone?.address && modifyParkingZone?.markers.length === 0) {
      // everything is loaded, we have a correct address, but no markers
      // attempting to automatically and silently set markers
      console.info("Fixup markers");
      await geocodeAddressSetMarkers(map, geocoder, modifyParkingZone.address, setMarkers, false);
    }

  };

  const recenterMap = async () => {
    if (!initialRecenterAlreadyDone && modifyParkingZone?.address && modifyParkingZone?.markers.length > 0) {

      // if we have some marker, we try to position it a bit nicer

      const marker = modifyParkingZone.markers[0]
      const address = modifyParkingZone.address;
      const formattedAddress = formatAddress(address);
      const {firstResult, firstGeometry} = await geocodeAddressFetchFirstResult(geocoder, address.countryCode, formattedAddress);

      if (firstGeometry.viewport.contains(marker.center)) {

        if (firstResult.types.includes("street_address")) {
          // when result is a street address, zoom to viewport
          map.fitBounds(firstGeometry.viewport);
          map.setZoom(map.getZoom() + 2) // inch a bit closer
        } else {
          // when result is not a street address, center on marker
          map.setCenter(marker.center)
        }
      }
    }
    setInitialRecenterAlreadyDone(true);
  };

  const onTilesLoaded = () => {
    fixupMarkers().
    then(recenterMap());
  }

  const memoizedDebouncedGeocoding = useCallback(
      _.debounce((map, geocoder, address, setMarkersCallback) => {

            const formattedAddress = formatAddress(address);
            NotificationManager.info(
                t('EditParkingZoneModal.geocodingAddressLong', {formattedAddress: formattedAddress}),
                t('EditParkingZoneModal.geocodingAddress'),
                1500
            );
            geocodeAddressSetMarkers(map, geocoder, address, setMarkersCallback)
                .then(_ => {
                  // loader...
                })
                .finally({
                  // loader

                })
          },
          ADDRESS_GEOCODING_DELAY), []);

  const [newAddress, setNewAddress] = useState(); // to track when newAddress appears so we can recenter the map

  const onAddressAdded = (newAddress) => {
    setModifyParkingZone({
      ...modifyParkingZone,
      address: newAddress,
    });
    setNewAddress(newAddress);
  };

  useEffect(() => {
    if (newAddress && map && geocoder) {
      // map is loaded, geocoder is loaded
      // address is valid, begin geocoding sometimes in the future
      memoizedDebouncedGeocoding(map, geocoder, newAddress, setMarkers);
    }
  }, [newAddress]);

  const currencyDisplay = formatCurrencyCode(countryWatch)

  const priceLabel = t("EditParkingZoneModal.price") + " " + currencyDisplay;
  const reminderFeeLabel = t("EditParkingZoneModal.reminderFee") + " " + currencyDisplay;
  const additionalCostLabel = t("EditParkingZoneModal.additionalCost") + " " + currencyDisplay;
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={newModalStyle({ width: "70%", maxWidth: "900px" })}
    >
      <div className="modal__headerLine" />
      <div className={cn("modal__title", Style.modal__title)}>
        {t("EditParkingZoneModal.title")}
      </div>
      {isLoading && <ModalLoadingIndicator />}
      <form onSubmit={handleSubmit((data) => saveParkingZone(data))}>
        <div className={Style.formContainer}>
          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("EditParkingZoneModal.basicInfo")}
            </div>

            <div className={Style.pzThreeInputsContainer}>
              <div className={Style.container}>
                <LabelWithHR label={t("EditParkingZoneModal.pzNumber")} />
                <Controller
                  name="parkingZoneNumber"
                  control={control}
                  render={() => (
                    <input
                      type={"text"}
                      className={Style.textStyleInputEditModalDisabled}
                      name="parkingZoneNumber"
                      defaultValue={parkingZoneNumber}
                      disabled
                    />
                  )}
                />
              </div>
              <div className={Style.pricePaymentContainer}>
                <div
                  className={cn(
                    Style.container,
                    Style["container--margineRight"]
                  )}
                >
                  <LabelWithHR label={t("EditParkingZoneModal.pzName")} />
                  <Controller
                    name="parkingZoneName"
                    control={control}
                    render={() => (
                      <input
                        type={"text"}
                        className={Style.textStyleInputEditModal}
                        name="parkingZoneName"
                        onChange={(e) => {
                          setModifyParkingZone({
                            ...modifyParkingZone,
                            parkingZoneName: e.target.value,
                          });
                        }}
                        defaultValue={modifyParkingZone?.parkingZoneName}
                      />
                    )}
                  />
                </div>
                <div className={Style.container}>
                  <LabelWithHR label={t("ParkingZones.StaticCode")} />
                  <Controller
                    name="staticPinCode"
                    control={control}
                    render={() => (
                      <input
                        type={"text"}
                        className={Style.textStyleInputEditModal}
                        name="staticPinCode"
                        onChange={(e) => {
                          setModifyParkingZone({
                            ...modifyParkingZone,
                            staticPinCode: e.target.value,
                          });
                        }}
                        defaultValue={modifyParkingZone?.staticPinCode}
                        pattern="[1-9][0-9][0-9][0-9]"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className={Style.pzThreeInputsContainer}>
              <InputController
                  containerStyles={Style.container}
                  label={t("EditParkingZoneModal.numberOfParkingPlaces")}
                  name="numberOfParkingPlaces"
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type="number"
                  defaultValue={parkingZoneForUpdate?.numberOfParkingPlaces}
              />
              <div className={Style.pricePaymentContainer}>
                <InputController
                    containerStyles={cn(
                        Style.container,
                        Style["container--margineRight"]
                    )}
                    label={priceLabel}
                    name="price"
                    control={control}
                    type="number"
                    controllerClassName={Style.textStyleInputEditModal}
                    defaultValue={parkingZoneForUpdate?.price}
                />
                <InputController
                    containerStyles={Style.container}
                    label={t("EditParkingZoneModal.paymentPeriod")}
                    name="paymentPeriod"
                    control={control}
                    type="number"
                    controllerClassName={Style.textStyleInputEditModal}
                    defaultValue={parkingZoneForUpdate?.paymentPeriod}
                />
              </div>
            </div>
            <div className={Style.pzThreeInputsContainer}>
              <div className={Style.container}>
                <InputController
                    label={reminderFeeLabel}
                    name="reminderFee"
                    control={control}
                    controllerClassName={Style.textStyleInputEditModal}
                    type="number"
                    defaultValue={parkingZoneForUpdate?.reminderFee}
                />
                <div className="objectionEnabledCheckbox">
                  <input
                    type="checkBox"
                    className="checkboxObjectionEnabled"
                    onChange={() => {
                      setObjectionEnabled(!objectionEnabled);
                    }}
                    checked={objectionEnabled}
                  />
                  <div className="objectionEnabled" style={{ fontSize: 14 }}>
                    {t("ParkingZones.objectionEnabled")}
                  </div>
                </div>
              </div>
              <div className={Style.pricePaymentContainer}>
                <div
                  className={cn(
                    Style.container,
                    Style["container--margineRight"]
                  )}
                >
                  <InputController
                    label={additionalCostLabel}
                    name="additionalCost"
                    control={control}
                    type="number"
                    controllerClassName={Style.textStyleInputEditModal}
                    defaultValue={parkingZoneForUpdate?.additionalCost}
                  />
                  <div className="objectionEnabledCheckbox">
                    <input
                      disabled={modifyParkingZone?.videoAnalyzed}
                      type="checkBox"
                      className="checkboxObjectionEnabled"
                      onChange={() => {
                        setAdditionalInfoForTickets(!additionalInfoForTickets);
                      }}
                      checked={additionalInfoForTickets}
                    />
                    <div className="objectionEnabled" style={{ fontSize: 14 }}>
                      {t("ParkingZones.additionalInfoForTickets")}
                    </div>
                  </div>
                </div>
                <div className={Style.container}>
                  <div className={Style.container}>
                    <InputController
                        label={t("EditParkingZoneModal.reminderPaymentPeriod")}
                        name="reminderPaymentPeriod"
                        control={control}
                        type="number"
                        controllerClassName={Style.textStyleInputEditModal}
                        defaultValue={
                          parkingZoneForUpdate?.reminderPaymentPeriod
                        }
                    />
                    <div className="objectionEnabledCheckbox">
                      <input
                        type="checkBox"
                        className="checkboxObjectionEnabled"
                        onChange={(e) => {
                          setModifyParkingZone({
                            ...modifyParkingZone,
                            viacarIntegrationImmediately:
                              !modifyParkingZone?.viacarIntegrationImmediately,
                          });
                        }}
                        checked={
                          modifyParkingZone?.viacarIntegrationImmediately
                        }
                      />
                      <div
                        className="objectionEnabled"
                        style={{ fontSize: 14 }}
                      >
                        {t("ParkingZones.viacarIntegrationImmediately")}
                      </div>
                    </div>
                    <div className="objectionEnabledCheckbox">
                      <input
                          disabled={additionalInfoForTickets}
                          type="checkBox"
                          className="checkboxObjectionEnabled"
                          onChange={(e) => {
                            setModifyParkingZone({
                              ...modifyParkingZone,
                              videoAnalyzed:
                                  !modifyParkingZone?.videoAnalyzed,
                            });
                          }}
                          checked={
                            modifyParkingZone?.videoAnalyzed
                          }
                      />
                      <div
                          className="objectionEnabled"
                          style={{ fontSize: 14 }}
                      >
                        {t("EditParkingZoneModal.videoAnalysis")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Style.pzPermitTimeFreezTimeContainer}>
              <div className={Style.parkingPermitEnabled}>
                <input
                  type="checkBox"
                  className="checkboxObjectionEnabled"
                  onChange={() => {
                    setParkingPermitEnabled(!parkingPermitEnabled);
                  }}
                  checked={parkingPermitEnabled}
                />
                <div
                  className={Style.parkingPermitEnabledLabel}
                  style={{ fontSize: 14 }}
                >
                  {t("ParkingZones.enableParkingPermitAndFreezeTime")}
                </div>
              </div>

              <div className={Style.pzPermitTimeContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePickerInputWithLabel
                    label={t("ParkingZones.parkingPermitTime")}
                    value={parkingPermitTime}
                    setTime={setParkingPermitTime}
                    disabled={parkingPermitEnabled}
                  />
                </LocalizationProvider>
              </div>
              <div className={Style.pzPermitFreezeTimeContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePickerInputWithLabel
                    label={t("ParkingZones.freezeTime")}
                    value={freezeTime}
                    setTime={setFreezeTime}
                    disabled={parkingPermitEnabled}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>

          <div className={Style.section}>
            <AddressesComponent
                onAddressAdded={onAddressAdded}
                initialAddress={modifyParkingZone?.address}
                watch={watch}
                register={register}
                useGeocode={false}
            />
            <LabelWithHR label={t("ParkingZones.location")} />
            <div className={Style.section__map}>
              <GoogleMapReact
                  defaultCenter={
                      (modifyParkingZone?.markers &&
                          modifyParkingZone?.markers[0]?.center) ||
                      defaultMarker.center
                  }
                  defaultZoom={
                    modifyParkingZone?.markers &&
                    modifyParkingZone?.markers[0]?.center
                        ? 17
                        : defaultMarker.zoom
                  }
                  yesIWantToUseGoogleMapApiInternals
                  options={modifyParkingZone.markers.length > 0 ? colorMapOptions : dimmedMapOptions}
                  onClick={(value) => {
                    if (!manualParkingZoneMarkerEnabled) {
                      return;
                    }

                    if (modifyParkingZone.markers.length > 0) {
                      return;
                    }
                    setModifyParkingZone({
                      ...modifyParkingZone,
                      markers: [
                        ...modifyParkingZone?.markers,
                        {center: {lat: value.lat, lng: value.lng}},
                      ],
                    });
                  }}
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                    libraries: ["geocoding"],
                    language: i18n.language,
                  }}
                  onGoogleApiLoaded={({map, maps}) => {
                    setMap(map);
                    setGeocoder(new maps.Geocoder());
                  }}
                  onTilesLoaded={onTilesLoaded}

              >
                {modifyParkingZone.markers?.map((marker) => (
                    <Marker
                        lat={marker?.center?.lat}
                        lng={marker?.center?.lng}
                        key={marker?.center?.lat + marker?.center?.lng}
                        onClick={(value) => {
                          if (!manualParkingZoneMarkerEnabled) {
                            return;
                          }
                          setModifyParkingZone({
                            ...modifyParkingZone,
                            markers: [],
                          });
                        }}
                    />
                ))}
              </GoogleMapReact></div>
            <div className="objectionEnabledCheckbox">
              <input
                  type="checkBox"
                  className="checkboxObjectionEnabled"
                  disabled={modifyParkingZone?.videoAnalyzed || !modifyParkingZone?.markers?.length}
                  onChange={() => {
                    setUseMarkerForProximityCheck(
                        !useMarkerForProximityCheck
                    );
                  }}
                  checked={useMarkerForProximityCheck}
              />
              <div
                  className="objectionEnabled"
                  style={{fontSize: 14}}
              >
                {t("ParkingZones.proximityCheck")}
              </div>
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("EditParkingZoneModal.parkingZoneText")}
            </div>
            <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={parkingZoneText}
                init={{
                  width: "100%",
                  height: 250,
                  menubar: false,
                  paste_as_text: true,
                  language: "de",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family: Gilroy-Medium;font-size: 16px;line-height:20px;}",
              }}
            />
          </div>

          {isLoggedUserSysadmin && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "30px",
                  marginLeft: "30px",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ zIndex: 3, flex: 1 }}>
                  <LabelWithHR label={t("ParkingZones.contractDate")} />
                  <Controller
                    name="contractDate"
                    control={control}
                    render={() => (
                      <DatePicker
                        selected={contractDate}
                        popperPlacement="top"
                        dateFormat="dd.MM.yyyy"
                        className={Style.textStyleInputEditModal}
                        locale={i18n.language}
                        onChange={(date) => setContractDate(date)}
                      />
                    )}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: "40px" }}>
                  <LabelWithHR label={t("ParkingZones.salesArea")} />
                  <Controller
                    name="salesArea"
                    control={control}
                    render={() => (
                      <input
                        type="text"
                        className={Style.textStyleInputEditModal}
                        name="salesArea"
                        onChange={(e) => {
                          setSalesArea(e.target.value);
                        }}
                        defaultValue={salesArea}
                      />
                    )}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "30px",
                  marginLeft: "30px",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <LabelWithHR label={t("ParkingZones.parkingPayZone")} />
                  <Controller
                    name="parkingPayZone"
                    control={control}
                    render={() => (
                      <input
                        type="text"
                        className={Style.textStyleInputEditModal}
                        name="parkingPayZone"
                        onChange={(e) => {
                          setModifyParkingZone({
                            ...modifyParkingZone,
                            parkingPayZone: e.target.value,
                          });
                        }}
                        defaultValue={modifyParkingZone?.parkingPayZone}
                      />
                    )}
                  />
                </div>

                <div style={{ flex: 1, marginLeft: "40px" }}>
                  <LabelWithHR label={t("ParkingZones.customer")} />
                  <Controller
                    name="customer"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        className={Style.textStyleInputEditModal}
                        name="customer"
                        onChange={(e) => {
                          setModifyParkingZone({
                            ...modifyParkingZone,
                            customer: e.target.value,
                          });
                          field.onChange(e);
                        }}
                        defaultValue={modifyParkingZone?.customer}
                      />
                    )}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "30px",
                  marginLeft: "30px",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <LabelWithHR label={t("ParkingZones.pzComment")} />
                  <Controller
                    name="pzComment"
                    control={control}
                    render={() => (
                      <textarea
                        type="number"
                        style={{
                          minHeight: "80px",
                          boxShadow: "none",
                          border: "1px solid #c7c7c7",
                        }}
                        className={Style.textarea}
                        name="pzComment"
                        onChange={(e) => {
                          setPzComment(e.target.value);
                        }}
                        defaultValue={pzComment}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <LabelWithHR label={t("ParkingZones.representedBy")} />
              <Controller
                name="representedBy"
                control={control}
                render={() => (
                  <input
                    type="text"
                    className={Style.textStyleInputEditModal}
                    name="representedBy"
                    onChange={(e) => {
                      setModifyParkingZone({
                        ...modifyParkingZone,
                        representedBy: e.target.value,
                      });
                    }}
                    defaultValue={modifyParkingZone?.representedBy}
                  />
                )}
              />
            </div>
            <div style={{ flex: 1, marginLeft: "40px" }}>
              <LabelWithHR label={t("ParkingZones.relationToProperty")} />
              <Controller
                name="relationToProperty"
                control={control}
                render={() => (
                  <input
                    type="text"
                    className={Style.textStyleInputEditModal}
                    name="relationToProperty"
                    onChange={(e) => {
                      setModifyParkingZone({
                        ...modifyParkingZone,
                        relationToProperty: e.target.value,
                      });
                    }}
                    defaultValue={modifyParkingZone?.relationToProperty}
                  />
                )}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div style={{ flex: 1 }}>
              <LabelWithHR label={t("ParkingZones.notedBy")} />
              <Controller
                name="notedBy"
                control={control}
                render={() => (
                  <textarea
                    type="number"
                    style={{
                      minHeight: "80px",
                      boxShadow: "none",
                      border: "1px solid #c7c7c7",
                    }}
                    className={Style.textarea}
                    name="notedBy"
                    onChange={(e) => {
                      setModifyParkingZone({
                        ...modifyParkingZone,
                        notedBy: e.target.value,
                      });
                    }}
                    defaultValue={modifyParkingZone?.notedBy}
                  />
                )}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div style={{ flex: 1 }}>
              <LabelWithHR label={t("ParkingZones.injuredPerson")} />
              <Controller
                name="injuredPerson"
                control={control}
                render={() => (
                  <textarea
                    type="number"
                    style={{
                      minHeight: "80px",
                      boxShadow: "none",
                      border: "1px solid #c7c7c7",
                    }}
                    className={Style.textarea}
                    name="injuredPerson"
                    onChange={(e) => {
                      setModifyParkingZone({
                        ...modifyParkingZone,
                        injuredPerson: e.target.value,
                      });
                    }}
                    defaultValue={modifyParkingZone?.injuredPerson}
                  />
                )}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div style={{ flex: 1 }}>
              <LabelWithHR label={t("ParkingZones.additionalInfo")} />
              <Controller
                name="additionalInfo"
                control={control}
                render={() => (
                  <textarea
                    type="number"
                    style={{
                      minHeight: "80px",
                      boxShadow: "none",
                      border: "1px solid #c7c7c7",
                    }}
                    className={Style.textarea}
                    name="additionalInfo"
                    onChange={(e) => {
                      setModifyParkingZone({
                        ...modifyParkingZone,
                        additionalInfo: e.target.value,
                      });
                    }}
                    defaultValue={modifyParkingZone?.additionalInfo}
                  />
                )}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ zIndex: 2, flex: 1 }}>
              <LabelWithHR label={t("ParkingZones.dateOfCourtDecision")} />
              <Controller
                name="dateOfCourtDecision"
                control={control}
                render={() => (
                  <DatePicker
                    selected={dateOfCourtDecision}
                    popperPlacement="bottom"
                    dateFormat="dd.MM.yyyy"
                    className={Style.textStyleInputEditModal}
                    locale="de"
                    onChange={(date) => setDateOfCourtDecision(date)}
                  />
                )}
              />
            </div>
            <div style={{ zIndex: 2, flex: 1, marginLeft: "40px" }}>
              <LabelWithHR
                label={t("ParkingZones.publicationInCantonalNewspaper")}
              />
              <Controller
                name="publicationInCantonalNewspaper"
                control={control}
                render={() => (
                  <DatePicker
                    selected={publicationInCantonalNewspaper}
                    popperPlacement="bottom"
                    dateFormat="dd.MM.yyyy"
                    className={Style.textStyleInputEditModal}
                    locale="de"
                    onChange={(date) => setPublicationInCantonalNewspaper(date)}
                  />
                )}
              />
            </div>
            <div style={{ zIndex: 2, flex: 1, marginLeft: "40px" }}>
              <LabelWithHR label={t("ParkingZones.automaticSignedOut")} />
              <Controller
                name="automaticSignedOut"
                control={control}
                render={() => (
                  <DatePicker
                    selected={automaticSignedOut}
                    popperPlacement="bottom"
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    timeIntervals={60}
                    className={Style.textStyleInputEditModal}
                    locale="de"
                    onChange={(date) => setAutomaticSignedOut(date)}
                  />
                )}
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("EditParkingZoneModal.paymentInfo")}
            </div>
            <div className={Style.flexRowContainer}>
              <div
                className={cn(Style.container, Style["container--marginRight"])}
              >
                <LabelWithHR label={t("EditParkingZoneModal.iban")} />
                <Controller
                  name="iban"
                  control={control}
                  render={() => (
                    <input
                      type={"text"}
                      className={Style.textStyleInputEditModal}
                      name="iban"
                      onChange={(e) => {
                        setModifyParkingZone({
                          ...modifyParkingZone,
                          iban: e.target.value,
                        });
                      }}
                      defaultValue={modifyParkingZone?.iban}
                    />
                  )}
                />
              </div>
              <div
                className={cn(Style.container, Style["container--marginLeft"])}
              >
                <LabelWithHR label={t("EditParkingZoneModal.bic")} />
                <Controller
                  name="bic"
                  control={control}
                  render={() => (
                    <input
                      type={"text"}
                      className={Style.textStyleInputEditModal}
                      name="bic"
                      onChange={(e) => {
                        setModifyParkingZone({
                          ...modifyParkingZone,
                          bic: e.target.value,
                        });
                      }}
                      defaultValue={modifyParkingZone?.bic}
                    />
                  )}
                />
              </div>
            </div>
            <div className={Style.flexRowContainer}>
              <div
                className={cn(Style.container, Style["container--marginRight"])}
              >
                <LabelWithHR label={t("EditParkingZoneModal.payee")} />
                <Controller
                  name="payee"
                  control={control}
                  render={() => (
                    <input
                      type={"text"}
                      className={Style.textStyleInputEditModal}
                      name="payee"
                      onChange={(e) => {
                        setModifyParkingZone({
                          ...modifyParkingZone,
                          payee: e.target.value,
                        });
                      }}
                      defaultValue={modifyParkingZone?.payee}
                    />
                  )}
                />
              </div>
              <div
                className={cn(Style.container, Style["container--marginLeft"])}
              >
                <LabelWithHR
                  label={t("EditParkingZoneModal.financialInstitution")}
                />
                <Controller
                  name="financialInstitution"
                  control={control}
                  render={() => (
                    <input
                      type={"text"}
                      className={Style.textStyleInputEditModal}
                      name="financialInstitution"
                      onChange={(e) => {
                        setModifyParkingZone({
                          ...modifyParkingZone,
                          financialInstitution: e.target.value,
                        });
                      }}
                      defaultValue={modifyParkingZone?.financialInstitution}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {isLoggedUserSysadmin ? (
            <div>
              <div className={Style.section}>
                <div className={Style.section__title}>
                  {t("EditParkingZoneModal.billingInfo")}
                </div>
                <div className={Style.flexRowContainer}>
                  <div
                    className={cn(
                      Style.container,
                      Style["container--marginRight"]
                    )}
                  >
                    <LabelWithHR
                      label={t("EditParkingZoneModal.creditFormula")}
                    />
                    <Controller
                      name="creditFormula"
                      control={control}
                      render={() => (
                        <select
                          type="text"
                          className={Style.textStyleSelectEditModal}
                          name="creditFormula"
                          onChange={(e) => {
                            setModifyParkingZone({
                              ...modifyParkingZone,
                              creditFormula: e.target.value,
                            });
                          }}
                          defaultValue={modifyParkingZone?.creditFormula}
                        >
                          <option key={0} value={"FORMULA_1"}>
                            Formula 1 - UE
                          </option>
                          <option key={1} value={"FORMULA_2"}>
                            Formula 2 - UE + HD + MG
                          </option>
                          <option key={2} value={"FORMULA_3"}>
                            Formula 3 - all payments until the end of the period
                          </option>
                          <option key={3} value={"FORMULA_4"}>
                            Formula 4 - all payments until the end of the period
                            (less than UE otherwise UE)
                          </option>
                        </select>
                      )}
                    />
                  </div>
                </div>
                <div className={Style.flexRowContainer}>
                  <div
                    className={cn(
                      Style.container,
                      Style["container--marginRight"]
                    )}
                  >
                    <LabelWithHR
                      label={t("EditParkingZoneModal.invoicingFormula")}
                    />
                    <Controller
                      name="invoicingFormula"
                      control={control}
                      render={() => (
                        <select
                          type="text"
                          className={Style.textStyleSelectEditModal}
                          name="invoicingFormula"
                          onChange={(e) => {
                            setModifyParkingZone({
                              ...modifyParkingZone,
                              invoicingFormula: e.target.value,
                            });
                          }}
                          defaultValue={modifyParkingZone?.invoicingFormula}
                        >
                          <option key={0} value={"FIXED"}>
                            Fixed
                          </option>
                          <option key={1} value={"PERCENTAGE"}>
                            Percentage
                          </option>
                        </select>
                      )}
                    />
                  </div>
                  <div
                    className={cn(
                      Style.container,
                      Style["container--marginRight"]
                    )}
                  >
                    <LabelWithHR
                      label={t("EditParkingZoneModal.customerShare")}
                    />
                    <Controller
                      name="customerShare"
                      control={control}
                      render={() => (
                        <input
                          type="number"
                          step={0.001}
                          className={Style.textStyleInputEditModal}
                          name="customerShare"
                          onChange={(e) => {
                            setModifyParkingZone({
                              ...modifyParkingZone,
                              customerShare: e.target.value,
                            });
                          }}
                          defaultValue={modifyParkingZone?.customerShare}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={Style.flexRowContainer}>
                  <div
                    className={cn(
                      Style.container,
                      Style["container--marginRight"]
                    )}
                  >
                    <LabelWithHR
                      label={t("EditParkingZoneModal.commissionFormula")}
                    />
                    <Controller
                      name="commissionFormula"
                      control={control}
                      render={() => (
                        <select
                          type="text"
                          className={Style.textStyleSelectEditModal}
                          name="commissionFormula"
                          onChange={(e) => {
                            setModifyParkingZone({
                              ...modifyParkingZone,
                              commissionFormula: e.target.value,
                            });
                          }}
                          defaultValue={modifyParkingZone?.commissionFormula}
                        >
                          <option key={0} value={"FIXED"}>
                            Fixed
                          </option>
                          <option key={1} value={"PERCENTAGE"}>
                            Percentage
                          </option>
                        </select>
                      )}
                    />
                  </div>
                  <div
                    className={cn(
                      Style.container,
                      Style["container--marginRight"]
                    )}
                  >
                    <LabelWithHR
                      label={t("EditParkingZoneModal.commissionShare")}
                    />
                    <Controller
                      name="commissionShare"
                      control={control}
                      render={() => (
                        <input
                          type="number"
                          step={0.001}
                          className={Style.textStyleInputEditModal}
                          name="commissionShare"
                          onChange={(e) => {
                            setModifyParkingZone({
                              ...modifyParkingZone,
                              commissionShare: e.target.value,
                            });
                          }}
                          defaultValue={modifyParkingZone?.commissionShare}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("EditParkingZoneModal.documents")}
            </div>
            <div className={Style.container}>
              <>
                <label htmlFor="file-upload" className={Style.fileUpload}>
                  {t("EditParkingZoneModal.PowerOfAttorney")}
                </label>
                {powerOfAttorneyName !== "" && (
                  <label>
                    {powerOfAttorneyName === "" ? null : (
                      <div>
                        {showDocumentsNames(
                          powerOfAttorney,
                          setPowerOfAttorney,
                          powerOfAttorneyName,
                          setPowerOfAttorneyName
                        )}
                      </div>
                    )}
                  </label>
                )}
                <input
                  id="file-upload"
                  type="file"
                  accept=".pdf"
                  multiple
                  className={Style.uploadProof}
                  onChange={(e) => {
                    documentUpload(
                      e,
                      powerOfAttorney,
                      setPowerOfAttorney,
                      powerOfAttorneyName,
                      setPowerOfAttorneyName
                    );
                  }}
                />
              </>

              <>
                <label htmlFor="landRegister" className={Style.fileUpload}>
                  {t("EditParkingZoneModal.LandRegister")}
                </label>
                {landRegisterName !== "" && (
                  <label>
                    {landRegisterName === "" ? null : (
                      <div>
                        {showDocumentsNames(
                          landRegister,
                          setLandRegister,
                          landRegisterName,
                          setLandRegisterName
                        )}
                      </div>
                    )}
                  </label>
                )}
                <input
                  id="landRegister"
                  type="file"
                  accept=".pdf"
                  className="landRegister"
                  multiple
                  onChange={(e) => {
                    documentUpload(
                      e,
                      landRegister,
                      setLandRegister,
                      landRegisterName,
                      setLandRegisterName
                    );
                  }}
                />
              </>

              <>
                <label htmlFor="parkingPhoto" className={Style.fileUpload}>
                  {t("EditParkingZoneModal.PZPhotos")}
                </label>
                {parkingPhotoName !== "" && (
                  <label>
                    {parkingPhotoName === "" ? null : (
                      <div>
                        {showDocumentsNames(
                          parkingPhoto,
                          setParkingPhoto,
                          parkingPhotoName,
                          setParkingPhotoName
                        )}
                      </div>
                    )}
                  </label>
                )}
                <input
                  id="parkingPhoto"
                  type="file"
                  accept="image/jpg, image/png, image/jpeg"
                  className="parkingPhoto"
                  multiple
                  onChange={(e) => {
                    documentUpload(
                      e,
                      parkingPhoto,
                      setParkingPhoto,
                      parkingPhotoName,
                      setParkingPhotoName
                    );
                  }}
                />
              </>

              <>
                <label htmlFor="documentation" className={Style.fileUpload}>
                  {t("EditParkingZoneModal.PZDocumentation")}
                </label>
                {documentationName !== "" && (
                  <label>
                    {documentationName === "" ? null : (
                      <div>
                        {showDocumentsNames(
                          documentation,
                          setDocumentation,
                          documentationName,
                          setDocumentationName
                        )}
                      </div>
                    )}
                  </label>
                )}
                <input
                  id="documentation"
                  type="file"
                  accept=".pdf, .docx"
                  className="documentation"
                  multiple
                  onChange={(e) => {
                    documentUpload(
                      e,
                      documentation,
                      setDocumentation,
                      documentationName,
                      setDocumentationName
                    );
                  }}
                />
              </>
            </div>
          </div>

          <div className={Style.section}>
            <div className={cn(Style.section__title, Style.paddingTop)}>
              {t("Clients.Client")}
            </div>
            {isLoggedUserSysadmin ? (
            <div>
                <div className={Style.managerListContainer}>
                    <div className={Style.selectManager}>
                        <Controller
                            name="clientId"
                            control={control}
                            render={() => (
                                <Autocomplete
                                    className={Style.textStyleSelectEditModal}
                                    id={"clientId"}
                                    onChange={(e,value)  => {
                                      if(value) {
                                        setClientId(value.id);
                                        setValue("customer", `${value.name}`);
                                      }
                                    }}
                                    disableClearable
                                    value = {clients.find(x=>x.id===modifyParkingZone?.clientId) || null}
                                    renderInput={(params) => <TextField {...params} placeholder={t("Clients.Choose")}/>}
                                    options={clients}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(x) => `${x.name} | ${x.representativeName}`}
                                />
                            )}
                        />
                    </div>
                    <div className={Style.removeManager} onClick={() => {
                                                                           setClientId(null);
                                                                           setUseClientDocs(false);
                                                                           setValue("customer", "");
                        }}>
                        <img
                            alt=""
                            src={XIcon}
                            height="25px"
                            width="25px"
                        />
                    </div>
                </div>
                <div className="objectionEnabledCheckbox">
                  <input
                      type="checkBox"
                      className="checkboxObjectionEnabled"
                      disabled={!modifyParkingZone?.clientId}
                      onChange={() => {
                        console.warn(useClientDocs);
                        setUseClientDocs(
                            !useClientDocs
                        );
                      }}
                      checked={useClientDocs}
                  />
                  <div
                      className="objectionEnabled"
                      style={{ fontSize: 14 }}
                  >
                    {t("Clients.UseClientDocs")}
                  </div>
                </div>
            </div>
            ) : (
              <div className={Style.text}>
                {clients.find(client => client.id === modifyParkingZone.client.id)?.name || ""}
              </div>
            )}

            {viewersIds && renderEditViewers(viewersIds)}
            <button
                type="button"
                className={
                  viewersIds?.includes("0")
                      ? Style.addManagerButtonDisabled
                      :
                  Style.addManagerButton
                }
                onClick={() => {
                  setViewersIds([...viewersIds, "0"]);
                  setViewersComboBoxNames([
                    ...viewersComboBoxNames,
                    viewerCounter + 1,
                  ]);
                  setViewerCounter(viewerCounter + 1);
                }}
                disabled={viewersIds && viewersIds?.includes("0")}
            >
              {"+"+t("Viewer.addViewer")}
            </button>

            <div className={cn(Style.section__title, Style.paddingTop)}>
              {t("ParkingZones.chooseAdmin")}
            </div>
            {isLoggedUserSysadmin ? (
              <Controller
                name="keycloakAdminId"
                control={control}
                render={() => (
                  <Autocomplete
                    className={Style.textStyleSelectEditModal}
                  id={"keycloakAdminId"}
                  onChange={(e,value)  => {
                      if(value) {
                        setParkingZoneAdmin(value.id);
                      }
                  }}
                  disableClearable
                  value = {admins.find(x=>x.id===modifyParkingZone?.keycloakAdminId) || null}
                  renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                  options={admins}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                  />
                )}
              />
            ) : (
              <div className={Style.text}>
                {loggedUser?.firstName + " " + loggedUser?.lastName}
              </div>
            )}

            {managersIds && renderEditManagers(managersIds)}
            <button
              type="button"
              className={
                managersIds?.includes("0")
                  ? Style.addManagerButtonDisabled
                  : Style.addManagerButton
              }
              onClick={() => {
                setManagersIds([...managersIds, "0"]);
                setManagersComboBoxNames([
                  ...managersComboBoxNames,
                  counter + 1,
                ]);
                setCounter(counter + 1);
              }}
              disabled={managersIds && managersIds?.includes("0")}
            >
              {"+ Neue Manager"}
            </button>

            {beppolicemansIds && renderEditBeppolicemans(beppolicemansIds)}

            <div className={Style.buttonContainer}>
              <Button
                style={{
                  width: "25%",
                }}
                buttonStyle={{
                  width: "100%",
                }}
                disabled={isSubmitDisabled}
                text={t("Save")}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default EditParkingZoneModal;
