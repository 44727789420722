import i18n from "i18next";

export const currencyCode = (countryCode: string) => {
    switch (countryCode) {
        case "DE":
            return "EUR";
        case "CH":
            return "CHF";
        default:
            return "";
    }
};

export const formatCurrencyCode = (countryCode: string) => {
    const code = currencyCode(countryCode);
    if (!code) {
        return "";
    } else {
        return `(${code})`
    }
};

export const formatCurrency = (value: number, countryCode: string) => {
    const locale = `${i18n.language}-${countryCode}`;

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};