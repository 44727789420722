import Style from "./inputFieldWithLabel.module.css";
import preventMouseWheelValueChange from "./util";

const InputFieldWithLabel = (props) => {
    return (
        <div className="form-group" style={props.style}>
            <div className={Style.input__label}>
                {props.label + (props.required ? "*" : "")}
                <hr className={Style.input__hr} />
            </div>
            <div>
                <input
                    type={props.type ? props.type : "text"}
                    className={
                        props.disabled ? "textStyleInputDisable" : "textStyleInput"
                    }
                    style={props.inputStyle}
                    disabled={props.disabled}
                    {...props.register(props.registerName)}
                    {...props.validationProps}
                    required={props.required}
                    name={props.name}
                    defaultValue={props.defaultValue}
                    pattern={props.pattern}
                    autoComplete={props.autoComplete}
                    placeholder={props.placeholder}
                    onWheelCapture={preventMouseWheelValueChange}
                />
            </div>
        </div>
    );
};

export default InputFieldWithLabel;
