import React, {useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {useAuth} from "react-oidc-context";
import {ROLES} from "../consts";
import caseInsensitiveSort from "../utils/baseStringSort";
import {cellStyle, CustomToggle, dropdownItem, filterStyle, headerStyle,} from "./columnsGeneral";

export function ParkingZoneColumns({
  history,
  deleteParkingZone,
  t,
  keycloak,
  setSelectedParkingZoneId,
  setParkingZoneDetailsModalOpen,
}) {
  const auth = useAuth();
  const isSysAdmin = keycloak.hasRealmRole(ROLES.SYSADMIN, auth);
  const isViewer = keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth);
  const columnsSysadmin = useState([
    {
      title: t("ParkingZones.PZ number"),
      field: "parkingZoneNumber",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (row) => <div>{`${row.parkingZoneNumber.toString()}`}</div>,
    },
    {
      title: t("ParkingZones.PZ name"),
      field: "parkingZoneName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      customSort: (a, b) => caseInsensitiveSort(a.parkingZoneName, b.parkingZoneName),
    },
    {
      title: t("Clients.Client"),
      field: "customer",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("ParkingZones.Code"),
      field: "pinCode",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Parking zone Admin"),
      field: "zoneAdminName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Parking managers"),
      field: "managers",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (row) => <div>{row.managers || 0}</div>,
    },
    {
      title: t("Columns.Parking owners"),
      field: "owners",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (row) => <div>{row.owners || 0}</div>,
    },
    {
      title: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "4px" }}>
            {t("ParkingZones.Unpaid fines")}
          </div>
        </div>
      ),
      field: "fines",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      filterComponent: () => null,
      render: (row) => <div>{row.unpaidFines || 0}</div>,
    },
    {
      title: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "4px" }}>
            {t("ParkingZones.Paid fines")}
          </div>
        </div>
      ),
      field: "fines",
      filterPlaceholder: "eingeben",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      filterComponent: () => null,
      render: (row) => <div>{row.totalFines - row.unpaidFines || 0}</div>,
    },
    {
      title: t("Columns.Actions"),
      hidden: isViewer? true : false,
      headerStyle: { ...headerStyle, textAlign: "right" },
      filterComponent: () => null,
      field: "actions",
      cellStyle: {
        ...cellStyle,
        width: "10%",
        textAlign: "center",
        paddingRight: "60px",
      },

      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="sm"
            style={{
              backgroundColor: "rgb(0,90,140)",
              color: "white",
            }}
            popperConfig={{ strategy: "fixed" }}
          >
            {dropdownItem(() => {
              setParkingZoneDetailsModalOpen(true);
              setSelectedParkingZoneId(row.id);
            }, t("ParkingZones.Details"))}
            {!row?.parkingPayZone && (
              <>
                {dropdownItem(
                  () => history.push(`/whitelist/${row.id}`),
                  t("ParkingZones.Whitelist")
                )}

                {dropdownItem(
                  () => history.push(`/blacklist/${row.id}`),
                  t("ParkingZones.Blacklist")
                )}
              </>
            )}
            <Dropdown.Divider />
            {dropdownItem(
              () => deleteParkingZone(row.id),
              t("ParkingZones.Archive parking zone")
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]);
  const columnsPzAdmin = useState([
    {
      title: t("ParkingZones.PZ number"),
      field: "parkingZoneNumber",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (row) => <div>{`${row.parkingZoneNumber.toString()}`}</div>,
    },
    {
      title: t("ParkingZones.PZ name"),
      field: "parkingZoneName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("ParkingZones.customer"),
      field: "customer",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: isViewer? t("Columns.Beppoliceman") : t("Columns.Parking owners"),
      field: "owners",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (row) => <div>{row.owners || 0}</div>,
    },
    {
      title: t("Columns.Violators"),
      field: "violators",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (row) => <div>{row.violators || 0}</div>,
    },
    {
      title: t("Columns.AmountCHF"),
      hidden: isViewer? false : true,
      field: "price",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (row) => <div>{row.price || 0}</div>,
    },
    {
          title: t("Violations.UserStatus.OPEN"),
          hidden: isViewer? false : true,
          field: "numOfOpenTickets",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
          render: (row) => <div>{row.numOfOpenTickets || 0}</div>,
    },
    {
          title: t("Violations.UserStatus.PAID"),
          hidden: isViewer? false : true,
          field: "numOfPaidTickets",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
          render: (row) => <div>{row.numOfPaidTickets || 0}</div>,
    },
    {
          title: t("Violations.UserStatus.CANCELLED"),
          hidden: isViewer? false : true,
          field: "numOfCancelledTickets",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
          render: (row) => <div>{row.numOfCancelledTickets || 0}</div>,
    },
    {
          title: t("Violations.UserStatus.CLOSED"),
          hidden: isViewer? false : true,
          field: "numOfClosedTickets",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
          render: (row) => <div>{row.numOfClosedTickets || 0}</div>,
        },
    {
      title: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "4px" }}>
            {t("ParkingZones.Unpaid fines")}
          </div>
        </div>
      ),
      hidden: isViewer? true : false,
      field: "fines",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      filterComponent: () => null,
      render: (row) => <div>{row.unpaidFines || 0}</div>,
    },
    {
      title: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "4px" }}>
            {t("ParkingZones.Paid fines")}
          </div>
        </div>
      ),
      hidden: isViewer? true : false,
      field: "fines",
      filterPlaceholder: "eingeben",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      filterComponent: () => null,
      render: (row) => <div>{row.totalFines - row.unpaidFines || 0}</div>,
    },
    {
      title: t("Columns.Actions"),
      headerStyle: { ...headerStyle, textAlign: "right" },
      hidden: isViewer? true : false,
      filterComponent: () => null,
      field: "actions",
      cellStyle: {
        ...cellStyle,
        width: "10%",
        textAlign: "right",
        paddingRight: "60px",
      },

      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="sm"
            style={{
              backgroundColor: "rgb(0,90,140)",
              color: "white",
            }}
            popperConfig={{ strategy: "fixed" }}
          >
            {dropdownItem(() => {
              setParkingZoneDetailsModalOpen(true);
              setSelectedParkingZoneId(row.id);
            }, "Details")}

            {keycloak.hasRealmRole(ROLES.SYSADMIN, auth)
            || keycloak.hasRealmRole(ROLES.PARKING_ADMIN, auth)
                || keycloak.hasRealmRole(ROLES.PARKING_MANAGER, auth)?
                dropdownItem(
              () => history.push(`/whitelist/${row.id}`),
              t("ParkingZones.Whitelist")
            ):""}
            {keycloak.hasRealmRole(ROLES.SYSADMIN, auth)
              || keycloak.hasRealmRole(ROLES.PARKING_ADMIN, auth)
              || keycloak.hasRealmRole(ROLES.PARKING_MANAGER, auth)?
                  dropdownItem(
              () => history.push(`/blacklist/${row.id}`),
              t("ParkingZones.Blacklist")
            ):""}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]);

  return isSysAdmin ? columnsSysadmin[0] : columnsPzAdmin[0];
}
