import axios from "axios";
import {User} from "oidc-client-ts";

function getUser() {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}:${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`)
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const getHeaders = async () => {
  let headers = {};
  const selectedLang = localStorage.getItem("beppoSelectedLanguage");
  if (selectedLang !== null) {
    headers['Accept-Language'] = selectedLang;
  }
  const user = getUser();
  if (user?.access_token) {
    headers['Authorization'] = `Bearer ${user?.access_token}`;
  }

  return headers;
};

export const apiService = {
  get(url) {
    return getHeaders().then((headers) =>
      axios.get(url, {
        headers,
      })
    );
  },
  post(url, body) {
    return getHeaders().then((headers) =>
      axios.post(url, body, {
        headers,
      })
    );
  },
  postForm(url, body) {
    return getHeaders().then((headers) =>
      axios.postForm(url, body, {
        headers,
      })
    );
  },
  put(url, body) {
    return getHeaders().then((headers) =>
      axios.put(url, body, {
        headers,
      })
    );
  },
  putForm(url, body) {
    return getHeaders().then((headers) =>
      axios.putForm(url, body, {
        headers,
      })
    );
  },
  delete(url) {
    return getHeaders().then((headers) =>
      axios.delete(url, {
        headers,
      })
    );
  },
  patch(url, body) {
    return getHeaders().then((headers) =>
      axios.patch(url, body, {
        headers,
      })
    );
  },
  getDocument(url) {
    return getHeaders().then((headers) =>
      axios.get(url, {
        responseType: "blob",
        headers: headers,
      })
    );
  },
  getDocumentPost(url, body) {
    return getHeaders().then((headers) =>
      axios.post(url, body, {
        responseType: "blob",
        headers: headers,
      })
    );
  },
  getWithCancelToken(url, ourRequest) {
    return getHeaders().then((headers) =>
      axios.get(url, {
        headers: headers,
        cancelToken: ourRequest.token,
      })
    );
  },
  postWithCancelToken(url, body, ourRequest) {
      return getHeaders().then((headers) =>
        axios.post(url, body, {
          headers: headers,
          cancelToken: ourRequest.token,
        })
      );
    },
};
