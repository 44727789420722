import {Tooltip} from "@material-ui/core";
import cn from "classnames";
import moment from "moment";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {clearGasStationForDetails, getGasStationForUpdate,} from "../../actions/gasStation";
import AddressDisplaySingle from "../../common/address/AddressDisplay";
import Button from "../../common/button/button";
import {getClientLogo, isAralDeClientId} from "../../common/client/clientHelper";
import EntityDetail from "../../common/entityDetail/entityDetail";
import {ROLES} from "../../consts";
import ModalLoadingIndicator from "../../reusable/modalLoadingIndicator";
import {newModalStyle} from "../../reusable/styles";
import {formatCurrency, formatCurrencyCode} from "../../utils/formatter";
import {mapUser} from "../../utils/mapUser";
import Style from "./gasStationDetailsModal.module.css";

const GasStationDetailsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gasStation = useSelector(
    (state) => state.gasStation.gasStationForDetails
  );
  const { isLoading } = useSelector((state) => state.loader);
  const auth = useAuth();
  const loggedUser = mapUser(auth);

  useEffect(() => {
    dispatch(getGasStationForUpdate(props.gasStationId));
    return () => {
      dispatch(clearGasStationForDetails());
    };
  }, [dispatch, props.gasStationId]);


  const showDocumentsNames = (names, urls) => {
    const urlArray = urls?.split(",");
    return names?.split("|")?.map((n, index) => (
      <div key={index} className={Style.uploadedFileName}>
        {<div onClick={() => window.open(urlArray[index], "_blank")}>{n}</div>}
      </div>
    ));
  };

  const SectionTitle = (props) => {
    return <div className={props.style}>{props.title}</div>;
  };

  const isGasStationEditDisabled = () => {
    if (loggedUser?.roles?.includes(ROLES.SYSADMIN)) {
      return false;
    }
    //Only sys admin can edit GS
    return true;
  };

  const renderUsersNames = (nameList) => {
    const concatNames = (names) => {
      return names?.map((m, i) => (i === 0 ? m : ", " + m));
    };

    return (
      nameList &&
      (concatNames(nameList)?.join().length > 135 ? (
        <Tooltip title={concatNames(nameList)} placement="right" arrow>
          <div className={Style.usersNames}>{concatNames(nameList)}</div>
        </Tooltip>
      ) : (
        <div className={Style.usersNames}>{concatNames(nameList)}</div>
      ))
    );
  };

  const countryCode = gasStation?.address?.countryCode;

  if (!countryCode) {
    return null; // short circuit if we don't have a country code
  }

  const currencyDisplay = formatCurrencyCode(countryCode)

  const internationalFeeLabel = t("GasStations.internationalFee") + " " + currencyDisplay;
  const additionalFeeLabel = t("GasStations.additionalFee") + " " + currencyDisplay;
  const reminderFeeLabel = t("GasStations.reminderFee") + " " + currencyDisplay;
  const paymentFeeLabel = t("GasStations.paymentFee") + " " + currencyDisplay;
  return (
    <Modal
        open={props.open}
        closeOnEsc
        onClose={props.onClose}
        center
        styles={newModalStyle({width: "900px"})}
    >
      <div className="modal__headerLine"/>
      <div className={cn(Style.modalTitleWithLogo, "modal__title")}>
        <div id="gs_title">
          {t("GasStationDetailsModal.gasStation")}
        </div>
        {getClientLogo(gasStation?.client?.id)}
      </div>
      {isLoading && <ModalLoadingIndicator/>}
      <div className={Style.bodyWrapper}>
        <SectionTitle
            style={Style.sectionTitle}
            title={t("GasStationDetailsModal.generalInfo")}
        />
        <EntityDetail
            label={t("GasStationDetailsModal.gsNumber")}
            value={gasStation?.number}
        />
        {
          isAralDeClientId(gasStation?.client?.id) &&
          <EntityDetail
              label={t("EditGasStationModal.externalReferenceId", {customer: gasStation?.customer})}
              value={gasStation?.externalReferenceId}
          />
        }
        <EntityDetail
            label={t("GasStationDetailsModal.gsName")}
            value={gasStation?.name}
        />
        <EntityDetail
            label={t("GasStationDetailsModal.clientNumber")}
            value={gasStation?.clientNumber}
        />
        <EntityDetail
            label={t("GasStationDetailsModal.clientName")}
            value={gasStation?.clientName}
        />
        <EntityDetail
            label={t("GasStationDetailsModal.profitCenter")}
            value={gasStation?.profitCenter}
        />
        <EntityDetail
            label={t("GasStationDetailsModal.costCenter")}
            value={gasStation?.costCenter}
        />
        <EntityDetail
            label={t("Clients.Client")}
            value={gasStation?.customer}
        />

        <EntityDetail
            label={internationalFeeLabel}
            value={formatCurrency(gasStation?.internationalFee, countryCode)}
        />
        {gasStation.aralFuelTypes?
        <EntityDetail
          label={t("GasStations.fuelTypesTitle")}
          value={gasStation?.aralFuelTypes}
        /> : null}
        
        <div className="modal__title">
          {t("GasStations.inetntPaymentInfoTitle")}
        </div>
        <EntityDetail
            label={additionalFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.intentAdditionalFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.paymentPeriod")}
            value={gasStation?.categoryPaymentDetails?.intentPaymentPeriod}
        />

        <EntityDetail
            label={reminderFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.intentReminderFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.reminderPaymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails?.intentReminderPaymentPeriod
            }
        />

       {isAralDeClientId(gasStation?.client?.id) && <> <div className="modal__title">
          {t("GasStations.intentPartialPaymentWithoutFuelCash")}
        </div>
        <EntityDetail
            label={additionalFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.intentPartialPwfCashAdditionalFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.paymentPeriod")}
            value={gasStation?.categoryPaymentDetails?.intentPartialPwfCashPaymentPeriod}
        />

        <EntityDetail
            label={reminderFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.intentPartialPwfCashReminderFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.reminderPaymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails?.intentPartialPwfCashRemPayPer
            }
        /> </>}

        {isAralDeClientId(gasStation?.client?.id) && <> <div className="modal__title">
          {t("GasStations.intentPartialPaymentWithoutFuelCard")}
        </div>
        <EntityDetail
            label={additionalFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.intentPartialPwfCardAdditionalFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.paymentPeriod")}
            value={gasStation?.categoryPaymentDetails?.intentPartialPwfCardPaymentPeriod}
        />

        <EntityDetail
            label={reminderFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.intentPartialPwfCardReminderFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.reminderPaymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails?.intentPartialPwfCardRemPayPer
            }
        /> </>}

        {isAralDeClientId(gasStation?.client?.id) && <> <div className="modal__title">
          {t("GasStations.intentCardPaymentIncompletePaymentInfoTitle")}
        </div>
        <EntityDetail
            label={additionalFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.intentCardPaymentAdditionalFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.paymentPeriod")}
            value={gasStation?.categoryPaymentDetails?.intentCardPaymentPaymentPeriod}
        />

        <EntityDetail
            label={reminderFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.intentCardPaymentReminderFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.reminderPaymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails?.intentCardPaymentReminderPaymentPeriod
            }
        /> </>}

        {!isAralDeClientId(gasStation?.client?.id) && <>  <div className="modal__title">
          {t("GasStations.promissoryNotePaymentInfoTitle")}
        </div>
        <EntityDetail
            label={paymentFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.promissoryNotePaymentFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.gracePeriod")}
            value={gasStation?.categoryPaymentDetails?.promissoryNoteGracePeriod}
        />

        <EntityDetail
            label={additionalFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.promissoryNoteAdditionalFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.paymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails?.promissoryNotePaymentPeriod
            }
        />

        <EntityDetail
            label={reminderFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.promissoryNoteReminderFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.reminderPaymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails
                  ?.promissoryNoteReminderPaymentPeriod
            }
        /> </>}

        <div className="modal__title">
          {t("GasStations.debtNotePaymentInfoTitle")}
        </div>
        <EntityDetail
            label={additionalFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.debtNoteAdditionalFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.paymentPeriod")}
            value={gasStation?.categoryPaymentDetails?.debtNotePaymentPeriod}
        />

        <EntityDetail
            label={reminderFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.debtNoteReminderFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.reminderPaymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails?.debtNoteReminderPaymentPeriod
            }
        />
        <div className="modal__title">
          {t("GasStations.pumpSwappedPaymentInfoTitle")}
        </div>
        <EntityDetail
            label={additionalFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.pumpSwappedAdditionalFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.paymentPeriod")}
            value={gasStation?.categoryPaymentDetails?.pumpSwappedPaymentPeriod}
        />

        <EntityDetail
            label={reminderFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.pumpSwappedReminderFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.reminderPaymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails?.pumpSwappedReminderPaymentPeriod
            }
        />
        {!isAralDeClientId(gasStation?.client?.id) && <> <div className="modal__title">
          {t("GasStations.otherReasonPaymentInfoTitle")}
        </div>
        <EntityDetail
            label={additionalFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.otherReasonAdditionalFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.paymentPeriod")}
            value={gasStation?.categoryPaymentDetails?.otherReasonPaymentPeriod}
        />

        <EntityDetail
            label={reminderFeeLabel}
            value={formatCurrency(gasStation?.categoryPaymentDetails?.otherReasonReminderFee, countryCode)}
        />

        <EntityDetail
            label={t("GasStations.reminderPaymentPeriod")}
            value={
              gasStation?.categoryPaymentDetails?.otherReasonReminderPaymentPeriod
            }
        />
         </>}
        <SectionTitle
            style={Style.sectionTitle}
            title={t("ParkingZoneDetailsModal.paymentInfo")}
        />
        <EntityDetail
            label={t("ParkingZoneDetailsModal.payee")}
            value={gasStation?.payee}
        />
        <EntityDetail
            label={t("ParkingZoneDetailsModal.iban")}
            value={gasStation?.iban}
        />
        <EntityDetail
            label={t("ParkingZoneDetailsModal.bic")}
            value={gasStation?.bic}
        />
        <EntityDetail
            label={t("ParkingZoneDetailsModal.financialInstitution")}
            value={gasStation?.financialInstitution}
        />
        <EntityDetail
            label={t("ParkingZones.contractDate")}
            value={gasStation?.contractDate ? moment(gasStation.contractDate).format('DD.MM.yyyy') : null}
        />
        <EntityDetail
            label={t("ParkingZones.salesArea")}
            value={gasStation?.salesArea}
        />
        <EntityDetail
            label={t("GasStations.Others")}
            value={gasStation?.others}
        />
        <EntityDetail
            label={t("GasStations.RepresentedBy")}
            value={gasStation?.representedBy}
        />
        <EntityDetail
            label={t("GasStations.RelationToProperty")}
            value={gasStation?.relationToProperty}
        />
        <EntityDetail
            label={t("GasStations.NotedBy")}
            value={gasStation?.notedBy}
        />
        <EntityDetail
            label={t("GasStations.InjuredPerson")}
            value={gasStation?.injuredPerson}
        />
        <EntityDetail
            label={t("GasStations.AdditionalInfo")}
            value={gasStation?.additionalInfo}
        />

        <SectionTitle
            style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
            title={t("ParkingZoneDetailsModal.documents")}
        />
        <>
          <div className={Style.uploadedFileTitle}>
            {t("GasStations.PowerOfAttorney")}
          </div>
          {gasStation?.powerOfAttorneyName === "" ? null : (
              <div className={Style.text}>
                {showDocumentsNames(
                    gasStation?.powerOfAttorneyName,
                    gasStation?.powerOfAttorney
                )}
              </div>
          )}
          <div className={Style.uploadedFileTitle}>
            {t("GasStations.GSDocumentation")}
          </div>
          {gasStation?.documentationName === "" ? null : (
              <div className={Style.text}>
                {showDocumentsNames(
                    gasStation?.documentationName,
                    gasStation?.documentation
                )}
              </div>
          )}
        </>

        <SectionTitle
            style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
            title={t("Addresses.address")}
        />
        <div className={Style.text}>
          <AddressDisplaySingle address={gasStation?.address}/>
        </div>

        <SectionTitle
            style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
            title={t("Clients.Client")}
        />
        <div className={Style.text}>
          {gasStation?.client && `${gasStation.client.name} | ${gasStation.client.representativeName}`}
        </div>

        <SectionTitle
            style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
            title={t("Viewer.Viewers")}
        />
        {renderUsersNames(gasStation?.viewersNames)}

        <SectionTitle
            style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
            title={t("GasStationDetailsModal.administrator")}
        />
        <div className={Style.text}>{gasStation?.gasStationAdminName}</div>

        <SectionTitle
            style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
            title={t("GasStationDetailsModal.managers")}
        />
        {renderUsersNames(gasStation?.managersNames)}

        <SectionTitle
            style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
            title={t("GasStationDetailsModal.beppolicemans")}
        />

        {renderUsersNames(gasStation?.beppolicemansNames)}

        <div className={Style.editButton}>
          <Button
              text={t("GasStationDetailsModal.edit")}
              buttonStyle={{
                marginBottom: "20px",
                marginTop: "20px",
                height: "40px",
                width: "181px",
                marginRight: "10px",
                fontFamily: "Gilroy-Regular",
              }}
              onClick={() => props.openGasStationEditModal()}
              disabled={isGasStationEditDisabled()}
          />
        </div>
      </div>
    </Modal>
  );
};
export default GasStationDetailsModal;
