export const ROLES = {
  SYSADMIN: "system_admin",
  PARKING_ADMIN: "parking_zone_admin",
  PARKING_OWNER: "parking_zone_owner",
  PARKING_MANAGER: "parking_zone_manager",
  VISITOR: "visitor",
  GAS_STATION_ADMIN: "gas_station_admin",
  GAS_STATION_MANAGER: "gas_station_manager",
  PARKING_VIEWER: "parking_zone_viewer",
  GAS_STATION_VIEWER: "gas_station_viewer"
};

export const zurichMarker = {
  center: {
    lat: 47.373878,
    lng: 8.545094,
  },
  zoom: 11,
};

export const berlinMarker = {
  center: {
    lat: 52.521992,
    lng: 13.413244,
  },
  zoom: 11,
};

export const defaultMarker = zurichMarker;

export const manualParkingZoneMarkerEnabled = false;

export const ADDRESS_GEOCODING_DELAY = 1300;

export const aralFuelTypes = {
  "ADBLUE": "ADBLUE",
  "ERDGAS": "ERDGAS",
  "CNG": "CNG",
  "DIESEL": "DIESEL",
  "LKW - DIESEL": "LKW - DIESEL",
  "SUPER E10": "SUPER E10",
  "SUPER E5": "SUPER E5",
  "SUPERPLUS": "SUPERPLUS",
  "ULTIMATE 102": "ULTIMATE 102",
  "ULTIMATE DIESEL": 'ULTIMATE DIESEL',
  "LPG": 'LPG',
  "Aral HVO": 'Aral HVO'
};
