export const ticketStatus = {
  NEW: "NEW",
  ISSUED: "ISSUED",
  PAID: "PAID",
  OVERDUE: "OVERDUE",
  STOPPED: "STOPPED",
  DOWNPAID: "DOWNPAID",
  CHECK: "CHECK",
  DONE: "DONE",
  PENDING: "PENDING",
  GRACE: 'GRACE',
};

export const notificationType = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

export const parkingZoneOwnershipRequestStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DENIED: "DENIED",
};

export const requestType = {
  TICKET_CANCELLATION_REQUEST: "TICKET_CANCELLATION_REQUEST",
  PARKING_ZONE_OWNERSHIP_REQUEST: "PARKING_ZONE_OWNERSHIP_REQUEST",
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  GAS_STATION_OWNERSHIP_REQUEST: "GAS_STATION_OWNERSHIP_REQUEST",
  TICKET_FEE_CANCELLATION_REQUEST: "TICKET_FEE_CANCELLATION_REQUEST",
};

export const ticketLegalStatus = {
  NONE: "NONE",
  REMINDED: "REMINDED",
  SUED: "SUED",
  TERMINATED: "TERMINATED",
  INVOICE_SENT: "INVOICE_SENT",
  OPERATION_DOC_SENT: "OPERATION_DOC_SENT",
  TO_SUE: "TO_SUE",
  TO_BE_ENFORCED: "TO_BE_ENFORCED",
  PENALTY_FEE_DEMANDED: "PENALTY_FEE_DEMANDED"
};

export const gasStationTicketCategory = {
  INTENT: "INTENT",
  PROMISSORY_NOTE: "PROMISSORY_NOTE",
  DEBT_NOTE: "DEBT_NOTE",
  PUMP_SWAPPED: "PUMP_SWAPPED",
  OTHER_REASON: "OTHER_REASON",
  INTENT_PARTIAL_PAYMENT_WITHOUT_FUEL_CASH: "INTENT_PARTIAL_PAYMENT_WITHOUT_FUEL_CASH",
  INTENT_PARTIAL_PAYMENT_WITHOUT_FUEL_CARD: "INTENT_PARTIAL_PAYMENT_WITHOUT_FUEL_CARD",
  INTENT_CARD_PAYMENT_INCOMPLETE: "INTENT_CARD_PAYMENT_INCOMPLETE",
};

export const ticketSeriesType = {
  PARKING_ZONE: "PARKING_ZONE",
  DRIVE_OFF: "DRIVE_OFF",
};

export const reportType = {
    VIOLATIONS: "VIOLATIONS",
    DRIVE_OFFS: "DRIVE_OFFS",
}

export const colors = {
  red: 'rgba(193, 18, 28, 1)',
  yellow: 'rgba(240, 202, 0, 1)',
  green: 'rgba(0, 132, 85, 1)',
  blue: 'rgba(0, 90, 140, 1)',
  gray: 'rgba(142, 146, 145, 1)',
};

export const supportedCountries = new Map();
supportedCountries.set("CH", { label: "Switzerland" });
supportedCountries.set("DE", { label: "Germany" });

export const countryOptions = [
  { value: "CH", labelKey: "ModalEditTicket.CountryCodeOptions.CH" },
  { value: "D", labelKey: "ModalEditTicket.CountryCodeOptions.D" },
  { value: "F", labelKey: "ModalEditTicket.CountryCodeOptions.F" },
  { value: "I", labelKey: "ModalEditTicket.CountryCodeOptions.I" },
  { value: "A", labelKey: "ModalEditTicket.CountryCodeOptions.A" },
  { value: "FL", labelKey: "ModalEditTicket.CountryCodeOptions.FL" },
  { value: "other", labelKey: "ModalEditTicket.CountryCodeOptions.Other" },
];

export const vehicleTypeOptions = [
  { value: "Car", labelKey: "ModalEditTicket.VehicleTypeOptions.Car" },
  { value: "Truck", labelKey: "ModalEditTicket.VehicleTypeOptions.Truck" },
  { value: "Motorcycle", labelKey: "ModalEditTicket.VehicleTypeOptions.Motorcycle" },
  { value: "Other", labelKey: "ModalEditTicket.VehicleTypeOptions.Other" },
];