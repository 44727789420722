import MaterialTable from "@material-table/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {generateSueDocumentationForDO} from "../../actions/driveOffs";
import {changeTabInSidebar} from "../../actions/sidebar";
import {clearZendeskTicket} from "../../actions/ticket";
import {CLEAR_TICKET_FOR_EDIT} from "../../actions/types";
import {
  cancelTicket,
  cancelTicketFee,
  changeTicketArchivedStatus,
  changeTicketDocumentLanguageByNumber,
  changeTicketLegalStatusByPZAdminAndSysAdmin,
  clearOwnersForGSAndPZEdit,
  commentTicket,
  createOperationalDoc,
  generatePaymentSlipReminder,
  generatePaymentSlipWithPhotos,
  getAllDriveOffs,
  manuallyPayTicketBySysadmin,
  modifyTicket,
} from "../../actions/violations";
import {DriveOffColumns} from "../../columns/driveOffColumns";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import keycloak from "../../keycloak";
import LoadingIndicator from "../../reusable/loadingIndicator";
import {optionsGeneralArchivedTickets} from "../../reusable/optionsForTables";
import Pagination from "../../reusable/paginationForViolations";
import {
  materialTableStyle,
  paginationStyle,
  toolbarStyles,
  useStyles,
  useStylesForMaterial,
} from "../../reusable/styles";
import Sidebar from "../../sidebar/sidebar";
import EditGasStationModal from "../gasStation/editGasStationModal";
import GasStationDetailsModal from "../gasStation/gasStationDetailsModal";
import ModalForPropotionalPicture from "../modalForPropotionalPicture";
import ModalForVideoPlaying from "../modalForVideoPlaying";
import ObjectionModal from "../objectionModal";
import OwnerDetailsModal from "../owner/ownerDetailsModal";
import DateModal from "./dateModal";
import ModalAddComment from "./modalAddComment";
import ModalAuditTicket from "./modalAuditTicket";
import ModalEditTicket from "./modalEditTicket";
import ModalManualPayment from "./modalManualPayment";
import ModalZendeskDetails from "./modalZendeskDetails";
import Style from "./violations.module.css";
import ViolatorsAddressLockedModal from "./violatorsAddressLockedModal";

export default function DriveOff() {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const classesForm = toolbarStyles();
  const dispatch = useDispatch();
  const { violations, last, numberOfElements, empty } = useSelector(
    (state) => state.violations
  );
  const { isLoading } = useSelector((state) => state.loader);
  const [showPictures, setShowPictures] = useState(false);
  const [showVideos, setShowVideos] = useState(false);

  const [violatorDetails, setViolatorDetails] = useState(null);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [openManualPaymentModal, setOpenManualPaymentModal] = useState(false);
  const [objectionOpen, setObjectionOpen] = useState(false);
  const [objection, setObjection] = useState({});
  const [height, setHeight] = useState(window.innerHeight);
  const [showArchivedList, setShowArchivedList] = useState(false);
  const [showArchivedTickets, setShowArchivedTickets] = useState(false);
  const [archivedCellStyle, setArchivedCellStyle] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [ticketAuditModal, setTicketAuditModal] = useState(false);
  const [ticketForAudit, setTicketForAudit] = useState(false);
  const [editTicketModal, setEditTicketModal] = useState(false);
  const [ticketForEdit, setTicketForEdit] = useState(false);
  const [ticketNumberInCommentModal, setTicketNumberInCommentModal] =
    useState(false);
  const [commentDefaultValue, setCommentDefaultValue] = useState("");
  const [openViolatorsAddressLocked, setOpenViolatorsAddressLocked] =
    useState(false);

  const [dateModalOpen, setDateModalOpen] = useState(false);

  const [zendeskDetailsModalOpen, setZendeskDetailsModalOpen] = useState(false);
  const [zendeskId, setZendeskId] = useState(null);

  const [openModalOwnerDetails, setOpenModalOwnerDetails] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedGasStationZoneId, setSelectedGasStationZoneId] = useState("");
  const [openModalGasStationZoneDetails, setOpenModalGasStationZoneDetails] =
    useState(false);

  const [openModalEditGasStationZone, setOpenModalEditGasStationZone] =
    useState(false);

  const handleCloseModalForPictures = (event, newValue) => {
    setShowPictures(false);
  };

  const handleCloseModalForVideos = (event, newValue) => {
    setShowVideos(false);
  };

  const [country, setCountry] = useState("CH");

  const handleCountry = (event, newCountry) => {
      if (newCountry !== null) {
          setCountry(newCountry);
      }
  }

  const handleResize = () => {
    setHeight(window.innerHeight);
  };
  const refDate = useRef(null);

  const [filter, setFilter] = useState({
    take: 10,
    skip: 0,
    page: 0,
    sort: {
      path: "date",
      desc: true,
    },
    filter: {
      and: [
        {
          filter: "neq",
          path: "status",
          value: "NEW",
        },
        {
          filter: "eq",
          path: "deleted",
          value: false,
        },
        {
          filter: "isnull",
          path: "parkingZone",
        },
        {
          filter: "eq",
          path: "archived",
          value: false,
        },
        {
          filter: "con",
          path: "number",
          value: "",
        },
        {
          filter: "con",
          path: "licensePlate",
          value: "",
        },
        {
          filter: "con",
          path: "gasStationZone.number",
          value: "",
        },
        {
          filter: "con",
          path: "reporterName",
          value: "",
        },
        {
          filter: "notnull",
          path: "status",
          value: "",
        },
        {
          filter: "notnull",
          path: "legalStatus",
          value: "",
        },
        {
          filter: "notnull",
          path: "userStatus",
          value: "",
        },
        {
          filter: "starts",
          path: "date",
          value: "",
        },
        //workaround placeholder for violatorAddress array element state cache (filter does effectively nothing and gets replaced with a proper filter for violatorAddress.status)
        {
          filter: "notnull",
          path: "id",
          value: "",
        },
      ],
    },
  });

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    dispatch(
      getAllDriveOffs(
        showArchivedTickets,
        setArchivedCellStyle,
        filter,
        ourRequest,
        country
      )
    );
    return () => {
      ourRequest.cancel("Cancelling in cleanup");
    };
  }, [dispatch, showArchivedTickets, filter, country]);

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const generateDocumentation = async (number, licencePlate) => {
    await dispatch(generateSueDocumentationForDO(number, licencePlate));
  };

  const createOperationalDocument = (number, licencePlate) => {
    dispatch(createOperationalDoc(number, licencePlate));
  };

  const generateReminderPaymentSlip = async (number, licencePlate) => {
    await dispatch(
      generatePaymentSlipReminder(
        number,
        licencePlate,
        localStorage.getItem("beppoSelectedLanguage")
      )
    );
  };

  const generatePaymentSlip = async (number, licencePlate) => {
    await dispatch(
      generatePaymentSlipWithPhotos(
        number,
        licencePlate,
        localStorage.getItem("beppoSelectedLanguage")
      )
    );
  };

  const cancelViolation = async (id) => {
    await dispatch(cancelTicket(id));
  };

  const manuallyPayTicket = async (manualPayment) => {
    dispatch(manuallyPayTicketBySysadmin(manualPayment));
  };

  const cancelViolationFee = async (id) => {
    await dispatch(cancelTicketFee(id));
  };

  const changeTicketLegalStatus = async (id, status) => {
    await dispatch(changeTicketLegalStatusByPZAdminAndSysAdmin(id, status));
  };

  const changeTicketDocumentLanguage = async (number, language) => {
    await dispatch(changeTicketDocumentLanguageByNumber(number, language));
  };


  const archiveTicket = async (number) => {
    await dispatch(changeTicketArchivedStatus(number));
  };

  const toogleModalForComment = () => {
    setCommentDefaultValue("");
    setOpenCommentModal(!openCommentModal);
  };

  const addComment = (data) => {
    const dataForEdit = {
      ticketNumber: ticketNumberInCommentModal,
      comment: data.comment,
    };

    dispatch(commentTicket(dataForEdit));
    toogleModalForComment();
  };

  const editTicket = (data) => {
    dispatch(modifyTicket(data, setEditTicketModal));
  };

  const openGasStationZoneEditModal = () => {
    setOpenModalEditGasStationZone(true);
    setOpenModalGasStationZoneDetails(false);
  };

  useEffect(() => {
    dispatch(changeTabInSidebar(9));
  }, [dispatch]);

  const modifyArray = (array, name, value) => {
    const newArray = array.map((element) => {
      if (element.path == name) {
        element.value = value;
      }
      return element;
    });
    return newArray;
  };

  function toolbar() {
    return (
      <div className={classesForm.root}>
        <div className={Style.header}>
          <div className={Style.header__title}>{t("Sidebar.DriveOff")}</div>
        </div>
         <Grid container direction="row" className={Style.header}>
             <div style={{ display: 'flex' }}>
                 <p className={Style.label__text}>
                     {t("Violations.chooseCountry")}
                 </p>
                 <ToggleButtonGroup
                     value={country}
                     exclusive={true}
                     onChange={ handleCountry }
                     className={Style.countrySelect}
                 >
                     <ToggleButton value={"CH"}>CH</ToggleButton>
                     <ToggleButton value={"DE"}>DE</ToggleButton>
                 </ToggleButtonGroup>
             </div>
             <button
               className={Style.header__addButton}
               onClick={() => {
                 setShowArchivedList(!showArchivedList);
                 setShowArchivedTickets(!showArchivedTickets);
                 const newArray = modifyArray(
                   [...filter.filter.and],
                   "archived",
                   !showArchivedTickets
                 );
                 setFilter({ ...filter, filter: { and: newArray } });
               }}
             >
               <p className={Style.header__addButton__text}>
                 {!showArchivedList
                   ? t("Violations.showArchivedTickets")
                   : t("Violations.hideArchivedTickets")}
               </p>
             </button>
         </Grid>
      </div>
    );
  }

  useEffect(() => {
    dispatch(clearOwnersForGSAndPZEdit());
  }, []);

  const nextPage = () => {
    const skip = filter.skip;
    setFilter({ ...filter, skip: skip + 10, page: filter.page + 1 });
  };

  const previousPage = () => {
    const skip = filter.skip;
    setFilter({ ...filter, skip: skip - 10, page: filter.page - 1 });
  };

  const firstPage = () => {
    setFilter({ ...filter, skip: 0, page: 0 });
  };


  const clearZendesk = () => {
    dispatch(clearZendeskTicket());
  };

  const closeEditTicketModal = () => {
    setEditTicketModal(false);
    dispatch({
      type: CLEAR_TICKET_FOR_EDIT,
    });
  };

  function pagination(props) {
    return (
      <td style={paginationStyle}>
        <Pagination
          translate={t}
          {...props}
          classes={{ ...classesMaterial }}
          page={filter.page}
          last={last}
          empty={empty}
          numberOfElements={numberOfElements}
          nextPage={nextPage}
          previousPage={previousPage}
          rowsPerPage={10}
          firstPage={firstPage}
        />
      </td>
    );
  }

  const options = optionsGeneralArchivedTickets(height * 0.65, 44);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {isLoading && <LoadingIndicator />}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="text-center">
                <div className="row content">
                  <div className="col-sm-12">
                    <div className={classes.paper} style={{ width: '100%', minWidth: '3000px', overflowX: 'hidden'}}>
                      {showPictures && (
                        <ModalForPropotionalPicture
                          closePictures={handleCloseModalForPictures}
                          showPictures={showPictures}
                          images={images}
                        />
                      )}
                      {showVideos && (
                        <ModalForVideoPlaying
                          closeVideos={handleCloseModalForVideos}
                          showVideos={showVideos}
                          videos={videos}
                        />
                      )}
                      {openCommentModal && (
                        <ModalAddComment
                          open={openCommentModal}
                          addComment={addComment}
                          close={toogleModalForComment}
                          defaultValue={commentDefaultValue}
                        />
                      )}

                      {openModalOwnerDetails && (
                        <OwnerDetailsModal
                          open={openModalOwnerDetails}
                          setModalOpen={setOpenModalOwnerDetails}
                          owner={selectedOwner}
                          onClose={() => {
                            setOpenModalOwnerDetails(false);
                            setSelectedOwner("");
                          }}
                        />
                      )}

                      {zendeskDetailsModalOpen && (
                        <ModalZendeskDetails
                          open={zendeskDetailsModalOpen}
                          zendeskId={zendeskId}
                          owner={selectedOwner}
                          onClose={() => {
                            setZendeskDetailsModalOpen(false);
                            setZendeskId(null);
                            clearZendesk();
                          }}
                        />
                      )}

                      {objectionOpen && (
                        <ObjectionModal
                          objectionOpen={objectionOpen}
                          close={() => setObjectionOpen(false)}
                          objection={objection}
                        />
                      )}
                      {dateModalOpen && (
                        <DateModal
                          open={dateModalOpen}
                          close={() => setDateModalOpen(false)}
                          filter={filter}
                          setFilter={setFilter}
                          refDate={refDate}
                        />
                      )}
                      {openManualPaymentModal && (
                        <ModalManualPayment
                          manuallyPayTicket={manuallyPayTicket}
                          open={openManualPaymentModal}
                          close={() => setOpenManualPaymentModal(false)}
                          ticketForEdit={ticketForEdit}
                        />
                      )}
                      {editTicketModal && (
                        <ModalEditTicket
                          open={editTicketModal}
                          close={() => closeEditTicketModal()}
                          ticket={ticketForEdit}
                          editTicket={editTicket}
                        />
                      )}
                      {ticketAuditModal && (
                        <ModalAuditTicket
                          open={ticketAuditModal}
                          close={() => setTicketAuditModal(false)}
                          ticketForAudit={ticketForAudit}
                        />
                      )}

                      {openViolatorsAddressLocked && (
                        <ViolatorsAddressLockedModal
                          open={openViolatorsAddressLocked}
                          close={() => {
                            setOpenViolatorsAddressLocked(false);
                            setViolatorDetails(null);
                          }}
                          ticket={violatorDetails}
                        />
                      )}

                      {openModalGasStationZoneDetails && (
                        <GasStationDetailsModal
                          open={openModalGasStationZoneDetails}
                          onClose={() =>
                            setOpenModalGasStationZoneDetails(false)
                          }
                          gasStationId={selectedGasStationZoneId}
                          openGasStationEditModal={openGasStationZoneEditModal}
                        />
                      )}

                      {openModalEditGasStationZone && (
                        <EditGasStationModal
                          open={openModalEditGasStationZone}
                          closeOnEsc
                          onClose={() => setOpenModalEditGasStationZone(false)}
                        />
                      )}

                      <MaterialTable
                        icons={{
                          Filter: () => (
                            <img src={MagnifyingGlass} alt="search" />
                          ),
                        }}
                        title="Parking zones"
                        style={materialTableStyle}
                        data={violations}
                        columns={DriveOffColumns({
                          t: t,
                          setShowPictures: setShowPictures,
                          setImages: setImages,
                          setShowVideos: setShowVideos,
                          setVideos: setVideos,
                          setObjectionOpen: setObjectionOpen,
                          setObjection: setObjection,
                          generateDocumentation: generateDocumentation,
                          cancelViolation: cancelViolation,
                          cancelViolationFee: cancelViolationFee,
                          changeTicketLegalStatus: changeTicketLegalStatus,
                          changeTicketDocumentLanguage: changeTicketDocumentLanguage,
                          keycloak: keycloak,
                          manuallyPayTicket: manuallyPayTicket,
                          generateReminderPaymentSlip:
                            generateReminderPaymentSlip,
                          archiveTicket: archiveTicket,
                          showArchivedList: showArchivedList,
                          archivedCellStyle: archivedCellStyle,
                          generatePaymentSlip: generatePaymentSlip,
                          toogleModalForComment,
                          setTicketNumberInCommentModal,
                          setCommentDefaultValue,
                          setEditTicketModal,
                          setTicketForEdit,
                          setOpenModalOwnerDetails,
                          setSelectedOwner,
                          setFilter: setFilter,
                          filter: filter,
                          setZendeskDetailsModalOpen:
                            setZendeskDetailsModalOpen,
                          setZendeskId: setZendeskId,
                          setDateModalOpen,
                          refDate: refDate,
                          setViolatorDetails,
                          setOpenViolatorsAddressLocked,
                          createOperationalDocument,
                          setTicketAuditModal: setTicketAuditModal,
                          setTicketForAudit: setTicketForAudit,
                          setOpenManualPaymentModal,
                          setSelectedGasStationZoneId,
                          setOpenModalGasStationZoneDetails,
                        })}
                        options={{
                          ...options,
                          sorting: false,
                          draggable: false,
                          tableLayout: "auto",
                        }}
                        components={{
                          Toolbar: (props) => toolbar(props),
                          Pagination: (props) => pagination(props),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
