import {
  createSimpleAxiosErrorNotification,
  createSimpleAxiosWarningNotification,
  createSimpleSuccessNotification
} from "../common/notifications/notification";
import {apiService} from "../utils/serviceApi";
import {
  ADD_GAS_STATION,
  CLEAR_GAS_STATION_FOR_DETAILS,
  CLEAR_GAS_STATION_FOR_EDIT,
  GET_ALL_GAS_STATION_ZONES,
  GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER,
  GET_GAS_STATION_FOR_UPDATE,
  SET_LOADER,
  UPDATE_GAS_STATION,
} from "./types";

export const getAllGasStations = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/admin`, {country})
    .then((res) => {
      dispatch({
        type: GET_ALL_GAS_STATION_ZONES,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const addGasStation = (data, closeModal) => async (dispatch) => {
  apiService
    .postForm(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station`, data)
    .then((res) => {
      if (res.status < 300) {
        dispatch({
          type: ADD_GAS_STATION,
          payload: res.data,
        });
        createSimpleSuccessNotification();
        closeModal();
      } else {
        createSimpleAxiosWarningNotification(res);
      }
    })
    .catch((e) => {
      createSimpleAxiosErrorNotification(e);
      console.log(e.response);
    });
};

export const editGasStation = (id, gasStation, closeModal) => (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .putForm(
      `${process.env.REACT_APP_SERVICE_URL}/api/gas-station/sysadmin/${id}`,
      gasStation
    )
    .then((res) => {
      dispatch({
        type: UPDATE_GAS_STATION,
        payload: res.data,
      });
      createSimpleSuccessNotification();
      closeModal();
    })
    .catch((e) => {
      createSimpleAxiosErrorNotification(e);
      console.error(e.response);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getGasStationForUpdate = (id) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/forUpdate/${id}`)
    .then((res) => {
      dispatch({
        type: GET_GAS_STATION_FOR_UPDATE,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const clearGasStationForDetails = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GAS_STATION_FOR_DETAILS,
  });
};

export const clearGasStationForEdit = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GAS_STATION_FOR_EDIT,
  });
};

export const getAllGasStationsForGSAdmin = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/GSAdmin`, {country})
    .then((res) => {
      dispatch({
        type: GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};
