import {Controller} from "react-hook-form";
import LabelWithHR from "../labels/labelWithHR";
import preventMouseWheelValueChange from "./util";

/**
 * @deprecated use InputFieldWithLabel instead
 * @returns {JSX.Element}
 * @constructor
 */
const InputController = ({
                             containerStyles,
                             label,
                             name,
                             control,
                             type,
                             controllerClassName,
                             defaultValue,
                             disabled,
                             required,
                             min,
                         }) => {

    return (
        <div className={containerStyles}>
            <LabelWithHR label={label} required={required}/>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field: { onChange, onBlur, value, ref }}) => {
                    return (
                        <input
                            type={type ? type : "text"}
                            className={controllerClassName}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref}
                            disabled={disabled}
                            required={required}
                            value={value}
                            min={min}
                            onWheelCapture={preventMouseWheelValueChange}
                        />
                    );
                }}
            />
        </div>
    );
};

export default InputController;
