import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {MultiSelect} from "react-multi-select-component";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    exportExcel,
    fetchChartData,
    getAllGasStations,
    getAllGasStationsForGSAdmin,
    getAllParkingZones,
    getAllParkingZonesForPZAdmin,
} from "../../actions/report";
import Button from "../../common/button/button";
import ChartBar from "../../common/chart/bar"
import {ROLES} from "../../consts";
import {colors} from '../../globals';
import keycloak from "../../keycloak";
import LoadingIndicator from "../../reusable/loadingIndicator";
import {toolbarStyles, useStyles} from "../../reusable/styles";
import Sidebar from "../../sidebar/sidebar";
import {currencyCode} from "../../utils/formatter";
import Style from "./report.module.css";


export default function Report() {
    const auth = useAuth();
    const { reportType } = useParams();
    const [selectedPZ, setSelectedPZ] = useState([]);
    const [market, setMarket] = useState("CH");
    const classes = useStyles();
    const {isLoading} = useSelector((state) => state.loader);
    const { t } = useTranslation();
    const classesForm = toolbarStyles();
    const dispatch = useDispatch();
    const { parkingZones } = useSelector((state) => state.report);
    const { gsZones } = useSelector((state) => state.report);
    const { chartData } = useSelector((state) => state.report);
    const initialRender = useRef(true);


  useEffect(() => {
      if (keycloak.hasRealmRole(ROLES.SYSADMIN, auth)) {
          if(reportType === "VIOLATIONS"){
            dispatch(getAllParkingZones(market));
          } else {
            dispatch(getAllGasStations(market));
          }
      } else {
          if(reportType === "VIOLATIONS"){
            dispatch(getAllParkingZonesForPZAdmin(market));
          } else {
            dispatch(getAllGasStationsForGSAdmin(market));
          }
      }
    }, [dispatch, reportType, market]);

    useEffect(() => {
        if (initialRender.current) {
          initialRender.current = false;
        } else {
          if (selectedPZ.length > 0) {
            dispatch(fetchChartData(selectedPZ.map(zone => zone.value), reportType, market));
          } else {
              // Reset chart data when no zones are selected
              dispatch({ type: 'RESET_CHART_DATA' });
          }
        }
    }, [selectedPZ, reportType, dispatch]);

    useEffect(() => {
        if (reportType === "VIOLATIONS" && parkingZones.length > 0) {
            setSelectedPZ(
                parkingZones
                    .filter(zone => zone.countryCode === market)
                    .map(zone => ({
                        value: zone.parkingZoneNumber,
                        label: `${zone.parkingZoneNumber} - ${zone.parkingZoneName}`
                    }
            )))
        }
    }, [parkingZones, market]);

    useEffect(() => {
        if (reportType === "DRIVE_OFFS" && gsZones.length > 0) {
            setSelectedPZ(
                gsZones
                    .filter(zone => zone.countryCode === market)
                    .map(zone => ({
                        value: zone.number,
                        label: `${zone.number} - ${zone.name}`
                    })))
        }
    }, [gsZones, market]);

    let pzOptions;
    if (reportType === "VIOLATIONS") {
        pzOptions = parkingZones
            .filter(zone => zone.countryCode === market)
            .map(zone => ({
                value: zone.parkingZoneNumber,
                label: `${zone.parkingZoneNumber} - ${zone.parkingZoneName}`
            }));
    } else {
        pzOptions = gsZones
            .filter(zone => zone.countryCode === market)
            .map(zone => ({
                value: zone.number,
                label: `${zone.number} - ${zone.name}`
            }));
    }

    const handleExportExcel = async () => {
        await dispatch(
            exportExcel(
                selectedPZ.map(zone => zone.value),
                reportType,
                localStorage.getItem("beppoSelectedLanguage"),
                market
            ))
    };

      const labels = chartData.chartLabels;
      const datasetsForTickets = [
        {
          label: t("Reports.Open"),
          backgroundColor: colors.blue,
          borderColor: colors.blue,
          borderWidth: 1,
          data: chartData.numberOfTickets ? chartData.numberOfTickets.OPEN : [],
        },
        {
          label: t("Reports.Paid"),
          backgroundColor: colors.green,
          borderColor: colors.green,
          borderWidth: 1,
          data: chartData.numberOfTickets ? chartData.numberOfTickets.PAID : [],
        },
        {
          label: t("Reports.Closed"),
          backgroundColor: colors.gray,
          borderColor: colors.gray,
          borderWidth: 1,
          data: chartData.numberOfTickets ? chartData.numberOfTickets.CLOSED : [],
        },
        {
          label: t("Reports.Cancelled"),
          backgroundColor: colors.red,
          borderColor: colors.red,
          borderWidth: 1,
          data: chartData.numberOfTickets ? chartData.numberOfTickets.CANCELLED : [],
        },
      ];

      const datasetsForCompensations = [
        {
          label: t("Reports.Open"),
          backgroundColor: colors.blue,
          borderColor: colors.blue,
          borderWidth: 1,
          data: chartData.compensationValue ? chartData.compensationValue.OPEN : [],
        },
        {
          label: t("Reports.Paid"),
          backgroundColor: colors.green,
          borderColor: colors.green,
          borderWidth: 1,
          data: chartData.compensationValue ? chartData.compensationValue.PAID : [],
        },
        {
          label: t("Reports.Closed"),
          backgroundColor: colors.gray,
          borderColor: colors.gray,
          borderWidth: 1,
          data: chartData.compensationValue ? chartData.compensationValue.CLOSED : [],
        },
        {
          label: t("Reports.Cancelled"),
          backgroundColor: colors.red,
          borderColor: colors.red,
          borderWidth: 1,
          data: chartData.compensationValue ? chartData.compensationValue.CANCELLED : [],
        },
      ];


    const selectedZoneNames = selectedPZ.map(zone => {
        return `${zone.label}`;
    });

    const currency = currencyCode(market);

    let titleNumberOfTickets;
    let titleCompensation;
    if (selectedPZ.length > 0) {
        if (selectedPZ.length > 3) {
            titleNumberOfTickets = <span dangerouslySetInnerHTML={{__html: `<b>${t("Reports.NoOfTickets")}</b> | ${selectedZoneNames.slice(0, 3).join(', ')}, ...`}} />;
            titleCompensation = <span dangerouslySetInnerHTML={{__html: `<b>${reportType === "VIOLATIONS" ? t("Reports.CompensationValueCHF", { currency }) : t("Reports.FuelValueCHF", { currency })}</b> | ${selectedZoneNames.slice(0, 3).join(', ')}, ...`}} />;
        } else {
            titleNumberOfTickets = <span dangerouslySetInnerHTML={{__html: `<b>${t("Reports.NoOfTickets")}</b> | ${selectedZoneNames.slice(0, 3).join(', ')}`}} />;
            titleCompensation = <span dangerouslySetInnerHTML={{__html: `<b>${reportType === "VIOLATIONS" ? t("Reports.CompensationValueCHF", { currency }) : t("Reports.FuelValueCHF", { currency })}</b> | ${selectedZoneNames.slice(0, 3).join(', ')}`}} />;
        }
    } else {
        titleNumberOfTickets = <span dangerouslySetInnerHTML={{__html: `<b>${t("Reports.NoOfTickets")}</b>`}} />;
        titleCompensation = <span dangerouslySetInnerHTML={{__html: `<b>${reportType === "VIOLATIONS" ? t("Reports.CompensationValueCHF", { currency }) : t("Reports.FuelValueCHF", { currency })}</b>`}} />;
    }

    const handleMarket = (event, newMarket) => {
        if (newMarket !== null) {
            setMarket(newMarket);
        }
    }

    return (
    <div className={classes.root}>
      <>
        <Sidebar/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator/>}

            <div className={classesForm.root}>
              <Grid container direction="row" className={Style.header}>
                <p className={Style.header__text}>
                  {reportType === "VIOLATIONS"
                    ? t("Reports.ParkingViolations")
                    : reportType === "DRIVE_OFFS"
                    ? t("Reports.DriveOffs")
                    : ""}
                </p>
                <div style={{ display: 'flex' }}>
                    <p className={Style.label__text}>
                        {t("Reports.chooseMarket")}
                    </p>
                    <ToggleButtonGroup
                        value={market}
                        exclusive={true}
                        onChange={ handleMarket }
                        className={Style.marketSelect}
                    >
                        <ToggleButton value={"CH"}>CH</ToggleButton>
                        <ToggleButton value={"DE"}>DE</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                {/* MultiSelect for Parking Zones */}
                <div style={{ display: 'flex'}}>
                    <p className={Style.label__text}>
                        {reportType === "VIOLATIONS"
                        ? t("Reports.SelectParkingZones")
                        : reportType === "DRIVE_OFFS"
                        ? t("Reports.SelectGasStation")
                        : ""}
                    </p>
                    <MultiSelect
                      options={pzOptions}
                      value={selectedPZ}
                      onChange={setSelectedPZ}
                      labelledBy="Select..."
                      className={Style.multiSelectContainer}
                    />
                <Button
                    style={{ width: '100px', height: '10px'}}
                    buttonStyle={{ height: "40px" }}
                    className={Style.exportButton}
                    text={t("Reports.Export")}
                    onClick={() => handleExportExcel()}
                />
                </div>
              </Grid>
            </div>
            <div className={Style.scrollableDiv}>
                <Divider/>
                {chartData.numberOfTickets ? (
                 <>
                    <ChartBar labels={labels} data={datasetsForTickets} title={titleNumberOfTickets}/>
                    <Divider style={{marginTop: "30px"}}/>
                    <ChartBar labels={labels} data={datasetsForCompensations} title={titleCompensation}/>
                    <Divider style={{marginTop: "30px"}}/>
                 </>
                ):
                (
                    <p>{t("Reports.NoDataAvailable")}</p>
                )}
            </div>


            </Container>
        </main>
    </>
    </div>
  );
}