import {GET_ALL_GAS_STATION_ZONES_FOR_BILLING, GET_ALL_PARKING_ZONES_FOR_BILLING, SET_LOADER} from "./types";
import {apiService} from "../utils/serviceApi";

export const calculatePzList = (data, setShowErrorModal) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .getDocumentPost(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/calculatePzList`, data)
    .then((response) => {
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PZL.csv");
        document.body.appendChild(link);
        link.click();
      } else {
        setShowErrorModal(true);
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};
export const calculateGsList = (data, setShowErrorModal) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
      .getDocumentPost(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/calculateGsList`, data)
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "GSL.csv");
          document.body.appendChild(link);
          link.click();
        } else {
          setShowErrorModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
};

export const calculateTicketList = (data, setShowErrorModal) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .getDocumentPost(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/calculateTicketList`, data)
    .then((response) => {
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ticketLists.zip");
        document.body.appendChild(link);
        link.click();
      } else {
        setShowErrorModal(true);
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const calculateGsTicketList = (data, setShowErrorModal) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
      .getDocumentPost(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/calculateTicketList`, data)
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ticketLists.zip");
          document.body.appendChild(link);
          link.click();
        } else {
          setShowErrorModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
};

export const getAllPzOptions = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/admin`, {country})
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_ALL_PARKING_ZONES_FOR_BILLING,
          payload: response.data,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllGsOptions = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
      .post(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/admin`, {country})
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_ALL_GAS_STATION_ZONES_FOR_BILLING,
            payload: response.data,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
};

export const calculateGsXMLTicketList = (data, setShowErrorModal) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
      .getDocumentPost(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/calculateGsXML`, data)
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ticketList.xml");
          document.body.appendChild(link);
          link.click();
        } else {
          setShowErrorModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
};

export const calculateGsSAPTicketList = (data, setShowErrorModal) => async (dispatch) => {
    dispatch({
        type: SET_LOADER,
        payload: true,
    });
    apiService
        .getDocumentPost(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/calculateGsSAP`, data)
        .then((response) => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "sap.csv");
                document.body.appendChild(link);
                link.click();
            } else {
                setShowErrorModal(true);
            }
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => {
            dispatch({
                type: SET_LOADER,
                payload: false,
            });
        });
};