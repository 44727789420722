import {Chart, registerables} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import {Bar} from 'react-chartjs-2';

Chart.register(...registerables);



const ChartBar = ({ labels, data, title }) => {


  const options = {
  responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      layout: {
          padding: {
            top: 30,
            bottom: 20,
          },
      },
      plugins: {
        datalabels: {
//          display: (context) => context.dataset.data[context.dataIndex] > 10,
            display: (context) => {
            const maxY = context.chart.scales['y'].max;
                        return context.dataset.data[context.dataIndex] > 0 && context.dataset.data[context.dataIndex] > maxY * 0.03;
            },
          color: 'white',
          anchor: 'center',
          align: 'center',
          offset: 0,
          formatter: (value, ctx) => {
            const stackedValues = ctx.chart.data.datasets.map((ds) => ds.data[ctx.dataIndex]);
            const dsIdxLastVisibleNonZeroValue = stackedValues.reduce((prev, curr, i) => !!curr && !ctx.chart.getDatasetMeta(i).hidden ? Math.max(prev, i) : prev, 0);
//            let total = 0;
//            if (!!value && ctx.datasetIndex === dsIdxLastVisibleNonZeroValue) {
//              total = stackedValues.filter((ds, i) => !ctx.chart.getDatasetMeta(i).hidden).reduce((sum, v) => sum + v, 0);
//              if (Number.isInteger(total)) {
//                    return `${value}`;
//                  } else {
//                    return `(${value.toFixed(2)}`;
//                  }
//            } else {
//                return `${value}`;
//            }
            return `${value}`;
          },
        },
        legend: {
            position: 'bottom',
            labels: {
              boxWidth: 10,
              usePointStyle: true,
            },
        },
      },
    };

  return (
    <div>
      <h5>{title}</h5>
      <Bar height={"100vh"} data={{ labels: labels, datasets: data }} options={options} plugins={[ChartDataLabels]}/>
    </div>
  );
};

export default ChartBar;
