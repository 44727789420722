import i18next from "i18next";
import {NotificationManager} from "react-notifications";
import {
  createErrorNotification,
  createSimpleAxiosErrorNotification,
  createSimpleAxiosWarningNotification,
  createSimpleSuccessNotification,
  createWarningNotification
} from "../common/notifications/notification";
import {colorMapOptions, dimmedMapOptions} from "../containers/parkingZone/marker";
import {apiService} from "../utils/serviceApi";
import {
  ADD_PARKING_ZONE,
  CLEAR_PARKING_ZONE_FOR_DETAILS,
  CLEAR_PZ_FOR_EDIT,
  CLOSE_CREATE_PARKING_ZONE_ERROR_MODAL,
  CLOSE_UPDATE_PARKING_ZONE_ERROR_MODAL,
  CREATE_PARKING_ZONE_ERROR,
  DELETE_PARKING_ZONE,
  GET_ALL_PARKING_ZONES,
  GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER,
  GET_PARKING_ZONE,
  GET_PARKING_ZONE_FOR_UPDATE,
  SET_LOADER,
  UPDATE_PARKING_ZONE,
  UPDATE_PARKING_ZONE_ERROR,
} from "./types";

export const getAllParkingZones = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/admin`, {country})
    .then((res) => {
      dispatch({
        type: GET_ALL_PARKING_ZONES,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const addParkingZone = (data, closeModal) => async (dispatch) => {
  apiService
    .postForm(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone`, data)
    .then((res) => {
      if (res.status === 417) {
        createSimpleAxiosWarningNotification(res)
        closeModal();
        return dispatch({
          type: CREATE_PARKING_ZONE_ERROR,
        });
      }
      dispatch({
        type: ADD_PARKING_ZONE,
        payload: res.data,
      });
      createSimpleSuccessNotification();
      closeModal();
    })
    .catch((e) => {
      createSimpleAxiosErrorNotification(e)
      console.log(e);
    });
};

export const deletePZ = (id) => async (dispatch) => {
  apiService
    .delete(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/${id}`)
    .then(() => {
      createSimpleSuccessNotification();
      dispatch({
        type: DELETE_PARKING_ZONE,
        payload: id,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getPaprkingZoneForUpdate = (id) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/forUpdate/${id}`
    )
    .then((res) => {
      dispatch({
        type: GET_PARKING_ZONE_FOR_UPDATE,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const clearPZForEdit = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PZ_FOR_EDIT,
  });
};

export const clearParkingZoneForDetails = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PARKING_ZONE_FOR_DETAILS,
  });
};

export const editParkingZone = (id, data, closeModal) => (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .putForm(
      `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/sysadmin/${id}`,
      data
    )
    .then((res) => {
      if (res.status === 417) {
        closeModal();
        return dispatch({
          type: UPDATE_PARKING_ZONE_ERROR,
        });
      }
      createSimpleSuccessNotification();
      dispatch({
        type: UPDATE_PARKING_ZONE,
        payload: res.data,
      });
      closeModal();
    })
    .catch((e) => {
      createSimpleAxiosErrorNotification(e);
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};
export const getAllParkingZonesForPZAdmin = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/PZAdmin`, {country})
    .then((res) => {
      dispatch({
        type: GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getPaprkingZone = (id) => async (dispatch) => {
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PARKING_ZONE,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const closeCreateParkingZoneErrorModal = () => async (dispatch) => {
  dispatch({
    type: CLOSE_CREATE_PARKING_ZONE_ERROR_MODAL,
  });
};

export const closeUpdateParkingZoneErrorModal = () => async (dispatch) => {
  dispatch({
    type: CLOSE_UPDATE_PARKING_ZONE_ERROR_MODAL,
  });
};

export const geocodeAddressFetchFirstResult = async (geocoder, countryCode, formattedAddress) => {
  const response = await geocoder.geocode(
      {
        address: formattedAddress,
        region: countryCode
      });

  const firstResult = response?.results[0];
  const firstGeometry = firstResult?.geometry; // ignore other results atm
  return {firstResult, firstGeometry};
}

export const geocodeAddressSetMarkers = async (map, geocoder, address, setMarkers, doNotify:boolean = true) : Promise<void> => {
  const formattedAddress = `${address.street}, ${address.city} ${address.zipCode}, ${address.countryCode}`;

  geocodeAddressFetchFirstResult(geocoder, address.countryCode, formattedAddress)
      .then(({firstResult, firstGeometry}) => {
        if (firstGeometry) {
          if (firstResult.partial_match && doNotify) {
            createWarningNotification(i18next.t('EditParkingZoneModal.partialMatchLong'), i18next.t('EditParkingZoneModal.partialMatch'));
          }
          const lat = firstGeometry.location?.lat();
          const lng = firstGeometry.location?.lng();
          const streetAddress = firstResult.formatted_address;

          // sanity check for QA
          const countryComponent = firstResult.address_components.find((element) => element.types.includes("country"));
          if (countryComponent?.short_name !== address.countryCode) {
            throw new Error(i18next.t('EditParkingZoneModal.countryCodeMismatch', {expectedCC: address.countryCode, actualCC: countryComponent?.short_name}));
          }

          map.fitBounds(firstGeometry.viewport);
          map.setZoom(map.getZoom() + 2); // zoom in a little bit closer
          map.setOptions(colorMapOptions);

          setMarkers([{
            center: { lat, lng }
          }]);

          doNotify && NotificationManager.success(
              i18next.t('EditParkingZoneModal.geocodingSuccessfulLong', {formattedAddress: formattedAddress, googleStreetAddress: streetAddress}),
              i18next.t('EditParkingZoneModal.geocodingSuccessful'),
              6000);

          // Optionally compare address parts with Google Maps result parts and offer to the user to fix some parts, e.g. zip code
        } else {
          map.setOptions(dimmedMapOptions);
          setMarkers([]);

          doNotify && createErrorNotification(i18next.t('EditParkingZoneModal.geocodingUnsuccessfulLong'), i18next.t('EditParkingZoneModal.geocodingUnsuccessful'));
        }
      })
      .catch((error) => {
        map?.setOptions(dimmedMapOptions);
        setMarkers([]);

        console.error(error);

        doNotify && createErrorNotification(
            i18next.t('EditParkingZoneModal.geocodingUnsuccessfulLong') + error.toString(),
            i18next.t('EditParkingZoneModal.geocodingUnsuccessful'));
      });
}

