import aralLogo from "../assets/organization/Aral_Logo.svg";

const ARAL_CLIENT_ID = Number(process.env.REACT_APP_ARAL_CLIENT_ID); // we need to cast to Number

export const getClientLogo = (clientId: number): HTMLImageElement => {
    switch (clientId) {
        // TODO SOCAR, Valora, GOFAST
        case ARAL_CLIENT_ID:
            // ARAL
            return <img id="client_logo" src={aralLogo} alt="logo" height={28}/>;
        default:
            return null;
    }
}

export const isAralDeClientId = (clientId: number): boolean => {
    return clientId === ARAL_CLIENT_ID;
}