import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import * as React from "react";

class MTablePaginationInner extends React.Component {
  handleFirstPageButtonClick = () => {
    this.props.firstPage();
  };

  handleBackButtonClick = () => {
    this.props.previousPage();
  };

  handleNextButtonClick = () => {
    this.props.nextPage();
  };

  render() {
    const {
      classes,
      page,
      last,
      empty,
      numberOfElements,
      rowsPerPage,
      translate,
      theme,
      showFirstLastPageButtons,
    } = this.props;
    const localization = {
      firstTooltip: translate("MaterialTable.pagination.firstAriaLabel"),
      previousTooltip: translate("MaterialTable.pagination.previousAriaLabel"),
      nextTooltip: translate("MaterialTable.pagination.nextAriaLabel"),
      labelDisplayedRows: translate(
        "MaterialTable.pagination.labelDisplayedRows"
      ),
      labelRowsPerPage: translate("MaterialTable.pagination.labelRowsPerPage"),
      ...this.props.localization,
    };
    return (
      <div className={classes.root}>
        {showFirstLastPageButtons && (
          <Tooltip title={localization.firstTooltip}>
            <span>
              <IconButton
                onClick={this.handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={localization.firstAriaLabel}
              >
                {theme.direction === "rtl" ? (
                  <this.props.icons.LastPage />
                ) : (
                  <this.props.icons.FirstPage />
                )}
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={localization.previousTooltip}>
          <span>
            <IconButton
              onClick={this.handleBackButtonClick}
              disabled={!this.props.page}
              aria-label={localization.previousAriaLabel}
            >
              {theme.direction === "rtl" ? (
                <this.props.icons.NextPage />
              ) : (
                <this.props.icons.PreviousPage />
              )}
            </IconButton>
          </span>
        </Tooltip>
        <Typography
          variant="caption"
          style={{
            flex: 1,
            textAlign: "center",
            alignSelf: "center",
            flexBasis: "inherit",
          }}
        >
          {
            empty ? "0 - 0" :
                `${page * rowsPerPage + 1} - ${page * rowsPerPage + numberOfElements}`
          }
        </Typography>
        <Tooltip title={localization.nextTooltip}>
          <span>
            <IconButton
              onClick={this.handleNextButtonClick}
              disabled={this.props.last}
              aria-label={localization.nextAriaLabel}
            >
              {theme.direction === "rtl" ? (
                <this.props.icons.PreviousPage />
              ) : (
                <this.props.icons.NextPage />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  }
}

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    display: "flex",
    // lineHeight: '48px'
  },
});

MTablePaginationInner.propTypes = {
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  last: PropTypes.bool,
  numberOfElements: PropTypes.number,
  empty: PropTypes.bool,
  firstPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  classes: PropTypes.object,
  localization: PropTypes.object,
  theme: PropTypes.any,
  showFirstLastPageButtons: PropTypes.bool,
};

MTablePaginationInner.defaultProps = {
  showFirstLastPageButtons: true,
};

const MTablePagination = withStyles(actionsStyles, { withTheme: true })(
  MTablePaginationInner
);

export default MTablePagination;
