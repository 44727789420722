import {apiService} from "../utils/serviceApi";
import {
    GET_ALL_GAS_STATION_ZONES,
    GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER,
    GET_ALL_PARKING_ZONES,
    GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER,
    GET_CHART_DATA,
    SET_LOADER,
} from "./types";

export const getAllParkingZones = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/admin`, {country})
    .then((res) => {
      dispatch({
        type: GET_ALL_PARKING_ZONES,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllParkingZonesForPZAdmin = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/PZAdmin`, {country})
    .then((res) => {
      dispatch({
        type: GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllGasStations = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/admin`, {country})
    .then((res) => {
      dispatch({
        type: GET_ALL_GAS_STATION_ZONES,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllGasStationsForGSAdmin = (country) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/gas-station/GSAdmin`, {country})
    .then((res) => {
      dispatch({
        type: GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const fetchChartData = (selectedPZIds: number[], reportType, market) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  const requestDto = { selectedPZIds, reportType, market };
  apiService
      .post(`${process.env.REACT_APP_SERVICE_URL}/api/report/chart/getSummaryChartDataLast12Months`, requestDto)
      .then((res) => {
        dispatch({
          type: GET_CHART_DATA,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

  export const exportExcel = (selectedPZIds, reportType, selectedLanguage, market) => async (dispatch) => {
      dispatch({
          type: SET_LOADER,
          payload: true,
      });
      const requestDto = { selectedPZIds, reportType, market };
      apiService
          .getDocumentPost(`${process.env.REACT_APP_SERVICE_URL}/api/report/export/excel/${selectedLanguage}`, requestDto)
          .then((response) => {
              if (response.status === 200) {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "tickets.xls");
                  document.body.appendChild(link);
                  link.click();
              } else {
//                  setShowErrorModal(true);
              }
          })
          .catch((e) => {
              console.log(e);
          })
          .finally(() => {
              dispatch({
                  type: SET_LOADER,
                  payload: false,
              });
          });
  };