import {
  CANCEL_TICKET,
  CHANGE_TICKET_LEGAL_STATUS,
  DELETE_TICKET,
  GENERATE_DOCUMENTATION,
  GET_ALL_VIOLATIONS,
  MANUALLY_PAY,
  ARCHIVED_TICKETS,
  COMMENT_TICKET,
  GENERATE_REMINDER_PAYMENT_SLIP,
  MODIFY_TICKET,
  GET_ZENDESK_TICKET,
  CLEAR_ZENDESK_TICKET,
  OWNERS_PARKING_ZONES_FOR_EDITING_TICKET,
  DONE_TICKET,
  GET_ALL_TICKET_AUDIT,
  CANCEL_TICKET_FEE,
  OWNERS_GAS_STATION_ZONES_FOR_EDITING_TICKET,
  CLEAR_OWNERS_LIST_FOR_GS_AND_PZ_EDIT,
  GET_TICKET_FOR_EDIT,
  CLEAR_TICKET_FOR_EDIT, CHANGE_TICKET_DOCUMENT_LANGUAGE,
} from "../actions/types";

const initialState = {
  violations: [],
  zendeskTicket: {},
  last: false,
  ownersParkingZonesForEditingTicket: [],
  ownersGasStationZonesForEditingTicket: [],
  ticketAudit: [],
  ticketForEdit: {},
};

const violationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VIOLATIONS:
      return {
        ...state,
        violations: action.payload,
        last: action.last,
        numberOfElements: action.numberOfElements,
        empty: action.empty
      };
    case GET_ALL_TICKET_AUDIT:
      return {
        ...state,
        ticketAudit: action.payload,
      };
    case DELETE_TICKET:
      return {
        ...state,
        violations: state.violations.filter((v) => v.id !== action.payload),
      };
    case CANCEL_TICKET:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.id !== action.payload.id ? v : action.payload
        ),
      };

    case CANCEL_TICKET_FEE:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.id !== action.payload.id ? v : action.payload
        ),
      };
    case DONE_TICKET:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.id !== action.payload.id ? v : action.payload
        ),
      };
    case GENERATE_DOCUMENTATION:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.number !== action.payload ? v : { ...v }
        ),
      };
    case GENERATE_REMINDER_PAYMENT_SLIP:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.number !== action.payload ? v : { ...v, legalStatus: "REMINDED" }
        ),
      };
    case MANUALLY_PAY:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.id !== action.payload.id ? v : action.payload
        ),
      };
    case MODIFY_TICKET:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.id !== action.payload.id ? v : action.payload
        ),
      };

    case CHANGE_TICKET_LEGAL_STATUS:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.id !== action.payload.id ? v : action.payload
        ),
      };

    case CHANGE_TICKET_DOCUMENT_LANGUAGE:
      return {
        ...state,
        violations: state.violations.map((v) =>
            v.id !== action.payload.id ? v : action.payload
        ),
      };

    case COMMENT_TICKET:
      return {
        ...state,
        violations: state.violations.map((v) =>
          v.number == action.payload.ticketNumber
            ? { ...v, comment: action.payload.comment }
            : v
        ),
      };
    case GET_ZENDESK_TICKET:
      return {
        ...state,
        zendeskTicket: action.payload,
      };
    case CLEAR_ZENDESK_TICKET:
      return {
        ...state,
        zendeskTicket: {},
      };
    case ARCHIVED_TICKETS:
      return {
        ...state,
        violations: state.violations.filter((v) => v.number !== action.payload),
      };

    case OWNERS_PARKING_ZONES_FOR_EDITING_TICKET:
      return {
        ...state,
        ownersParkingZonesForEditingTicket: action.payload,
      };
    case OWNERS_GAS_STATION_ZONES_FOR_EDITING_TICKET:
      return {
        ...state,
        ownersGasStationZonesForEditingTicket: action.payload,
      };
    case CLEAR_OWNERS_LIST_FOR_GS_AND_PZ_EDIT:
      return {
        ...state,
        ownersGasStationZonesForEditingTicket: [],
        ownersParkingZonesForEditingTicket: [],
      };

    case GET_TICKET_FOR_EDIT:
      return {
        ...state,
        ticketForEdit: action.payload,
      };
    case CLEAR_TICKET_FOR_EDIT:
      return {
        ...state,
        ticketForEdit: {},
      };
    default:
      return state;
  }
};

export default violationsReducer;
