import React, {useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {useAuth} from "react-oidc-context";
import {ROLES} from "../consts";
import caseInsensitiveSort from "../utils/baseStringSort";
import {cellStyle, CustomToggle, dropdownItem, filterStyle, headerStyle,} from "./columnsGeneral";


export function GasStationColumns({
  t,
  setSelectedGasStationId,
  keycloak,
  setGasStationDetailsModalOpen,
}) {
    const auth = useAuth();
  const isViewer = keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth);
  const columnsSysadmin = useState([
    {
      title: t("Columns.GS number"),
      field: "number",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (row) => <div>{`${row.number.toString()}`}</div>,
    },
    {
      title: t("Columns.GS name"),
      field: "name",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      customSort: (a, b) => caseInsensitiveSort(a.name, b.name),
    },
    {
      title: t("Clients.Client"),
      field: "customer",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Admin"),
      hidden: isViewer? true : false,
      field: "gasStationAdminName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("GasStations.totalFines"),
      field: "totalFines",
      hidden: isViewer? true : false,
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("GasStations.paidFines"),
      hidden: isViewer? true : false,
      field: "paidFines",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("GasStations.cancelledFines"),
      hidden: isViewer? true : false,
      field: "cancelledFines",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Beppoliceman") ,
      hidden: isViewer? false : true,
      field: "ownerCount",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      render: (row) => <div>{row.ownerCount || 0}</div>,
    },
    {
          title: t("Violations.UserStatus.OPEN"),
          hidden: isViewer? false : true,
          field: "numOfOpenTickets",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
          render: (row) => <div>{row.numOfOpenTickets || 0}</div>,
    },
    {
          title: t("Violations.UserStatus.PAID"),
          hidden: isViewer? false : true,
          field: "numOfPaidTickets",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
          render: (row) => <div>{row.numOfPaidTickets || 0}</div>,
    },
    {
          title: t("Violations.UserStatus.CANCELLED"),
          hidden: isViewer? false : true,
          field: "numOfCancelledTickets",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
          render: (row) => <div>{row.numOfCancelledTickets || 0}</div>,
    },
    {
          title: t("Violations.UserStatus.CLOSED"),
          hidden: isViewer? false : true,
          field: "numOfClosedTickets",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
          render: (row) => <div>{row.numOfClosedTickets || 0}</div>,
        },
    {
      title: t("Columns.Actions"),
      hidden: isViewer? true : false,
      headerStyle: {
        ...headerStyle,
        textAlign: "right",
        align: "right",
      },
      filterComponent: () => null,
      field: "actions",
      cellStyle: {
        ...cellStyle,
        width: "10%",
        textAlign: "left",
        paddingRight: "60px",
      },

      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="sm"
            style={{
              backgroundColor: "rgb(0,90,140)",
              color: "white",
            }}
            popperConfig={{ strategy: "fixed" }}
          >
            {dropdownItem(() => {
              setGasStationDetailsModalOpen(true);
              setSelectedGasStationId(row.id);
            }, "Details")}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]);

  return columnsSysadmin[0];
}
